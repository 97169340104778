import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import AltCard from "../../components/card/altCard";
import { Link } from "react-router-dom";
import "./styles.scss";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { BiSearchAlt2 } from "react-icons/bi";
import IconInput from "../../components/inputs/iconInput";
import Button from "../../components/buttons";
import Select from "../../components/select";
import { Stars, ChevronRight, Clock, User } from "../../assets/icons/icons";
import { withAlert } from "react-alert";
// import homeStore from "../../stores/superAdminStores/homeStore";
// import CommonStore from "../../stores/superAdminStores/commonStore";
import Loader from "react-loader-spinner";
import db from "../../services/firebase.config";
import moment from "moment";
import { numberWithCommas } from "../../helpers/commaSeparator";
import Map from "../../components/map";
import { HomeContext } from "../../contexts/HomeContext";
import {
	getCokitchens,
	getLogistics,
} from "../../utils/superAdminStores/homeStore";

const PartnerDB = ({ alert }) => {
	const {
		allOngoingTrips,
		setAllOngoingTrips,
		allCokitchens,
		setAllCokitchens,
		allLogistics,
		setAllLogistics,
	} = useContext(HomeContext);
	const partners = [];
	const [cokitchens, setCokitchens] = useState([]);
	const [activeOngoingTrip, setActiveOngoingTrip] = useState("");
	const [activeRiderPos, setActiveRiderPos] = useState("");
	const [location, setLocation] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const [searchValueAlt, setSearchValueAlt] = useState("");
	const [refreshCount, setRefreshCount] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showAllCompletedTrips, setShowAllCompletedTrips] = useState(false);
	const [logistics, setLogistics] = useState(null);
	const [ongoingTrips, setOngoingTrips] = useState([]);
	const ongoingTripsPlaceholder = ["", "", "", ""];
	const [todayCompletedTrips, setTodayCompletedTrips] = useState([]);
	const [yesterdayCompletedTrips, setYesterdayCompletedTrips] = useState([]);
	const [allCompletedTrips, setAllCompletedTrips] = useState([]);

	// const valid = (current) => {
	//   return current.isAfter(moment(startDate));
	// };
	// load data from store
	useEffect(async () => {
		await loadData();
		setLoading(false);
		setLogistics();
		setOngoingTrips(allOngoingTrips);
		sortTrips();
		setCokitchens(allCokitchens);
	}, []);

	useEffect(async () => {
		if (refreshCount) {
			await loadData();
		}
	}, [refreshCount]);

	useEffect(() => {
		setActiveOngoingTrip(ongoingTrips && ongoingTrips[0]);
	}, []);

	useEffect(() => {
		const activeRiders = async () => {
			if (
				activeOngoingTrip &&
				activeOngoingTrip.rider &&
				activeOngoingTrip.rider.id
			) {
				const response = await db
					.collection("rider_locations")
					.doc(activeOngoingTrip?.rider?.id)
					.onSnapshot(
						(items) => {
							// items.forEach((doc) => {
							//   //   ongoing.push(doc.data());
							// });
							setActiveRiderPos(items.data());
						},
						(err) => {
							console.log(err);
						}
					);
			} else {
				setActiveRiderPos(null);
			}
		};

		activeRiders();
	}, [activeOngoingTrip]);

	// load data from store
	const loadData = async () => {
		setLoading(true);
		let ongoing = [];
		// homeStore.updateOngoingTrips([]);
		const response = db.collection("tracking_orders").onSnapshot(
			(items) => {
				ongoing = [];
				items.forEach((doc, i, arr) => {
					ongoing.push(doc.data());
				});
				setOngoingTrips(ongoing);
				setAllOngoingTrips(ongoing);
				// homeStore.updateOngoingTrips(ongoing);
				// if (ongoingTrips.length === 0) {

				// }
			},
			(err) => {
				console.log(err);
			}
		);

		const data = await Promise.all([
			getLogistics(alert, true, (res) => setAllLogistics(res)),
			getCokitchens(alert, true, (res) => setAllCokitchens(res)),
		]);
		setLoading(false);
		setLogistics(allLogistics);
		setCokitchens(cokitchens);
	};

	// Sort trips into today, yesterday and all time
	const sortTrips = () => {
		let todTrips = [];
		let yesTrips = [];
		let today = moment().format("DD-MM-YYYY");
		let yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
		let allTrips =
			logistics?.trips && logistics.trips.filter((item, i) => item.completed);
		for (let i = 0, len = allTrips?.length || []; i < len; i++) {
			if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
				todTrips.push(allTrips[i]);
			} else if (
				moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
			) {
				yesTrips.push(allTrips[i]);
			}
		}
		setAllCompletedTrips(allTrips);
		setTodayCompletedTrips(todTrips);
		setYesterdayCompletedTrips(yesTrips);
	};
	let active = activeOngoingTrip && activeOngoingTrip;

	const fetchBlogs = async () => {};
	return (
		<Layout
			locationChange={() => setLocation()}
			details="active_nav_item"
			withHistoryBtn
			title={
				<h6 className="bold mr_30 text-success h4">
					LIVE on-going trips ({ongoingTrips ? ongoingTrips.length : "---"})
				</h6>
			}
			serachBar={
				<IconInput
					containerClass="width_full"
					placeholder="Search by code, rider name, customer name or address"
					withIcon
					icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
					value={searchValueAlt}
					onChange={(e) => setSearchValueAlt(e)}
				/>
			}
			options={cokitchens}
			onRefresh={() => setRefreshCount(refreshCount + 1)}
			refreshLoading={loading}
		>
			<section className="main_section  content_section flex_column_start mb_45">
				<section className="flex_row_top tab_column mb_45 width_full">
					<section className="left_section pr_25 width_full overflow-y-scroll scroll-container bg-white p-1 rounded-lg">
						{/* Left */}

						{/* Loader */}
						{ongoingTrips.length === 0 && (
							<Loader
								type="ThreeDots"
								color="#ff000060"
								height={10}
								width={50}
								className="loader-flex"
							/>
						)}
						{/* {loading &&
              ongoingTripsPlaceholder.map((item, i) => {
                return <AltCard key={i + "card"} loading={true} />;
              })} */}
						{ongoingTrips &&
							ongoingTrips

								.sort(
									(a, b) =>
										new Date(b.kitchen_dispatched_time) -
										new Date(a.kitchen_dispatched_time)
								)
								.filter(
									(item) =>
										(item?.rider?.first_name + " " + item?.rider?.last_name)
											?.toUpperCase()
											.includes(searchValueAlt.toUpperCase()) ||
										item?.order_code
											?.toUpperCase()
											.includes(searchValueAlt.toUpperCase()) ||
										(
											item?.calculated_order?.user?.first_name +
											" " +
											item?.calculated_order?.user?.last_name
										)
											?.toUpperCase()
											.includes(searchValueAlt.toUpperCase()) ||
										item?.calculated_order?.address_details?.address_line
											?.toUpperCase()
											.includes(searchValueAlt.toUpperCase())
								)

								.map((item, i) => {
									return (
										<AltCard
											loading={false}
											key={i + "card"}
											pathType="live"
											status={
												item && item.kitchen_dispatched ? "ongoing" : "pending"
											}
											onClick={() => setActiveOngoingTrip(item)}
											name={item.name}
											active={
												activeOngoingTrip && activeOngoingTrip.id === item.id
											}
											time={
												item?.kitchen_dispatched_time &&
												moment(item.kitchen_dispatched_time).format("LT")
											}
											date={
												item?.kitchen_dispatched_time &&
												moment(item.kitchen_dispatched_time).format(
													"DD MMM YYYY"
												)
											}
											id={item && item.order_code}
											from={"CoKitchen"}
											address={
												item &&
												item.calculated_order &&
												item.calculated_order.address_details &&
												item.calculated_order.address_details.address_line
											}
											price={
												item?.calculated_order?.delivery_fee &&
												numberWithCommas(
													parseInt(item.calculated_order.delivery_fee)
												)
											}
											toDestination={item.g}
											logistics={item.g}
											rider={
												(item &&
													item.rider &&
													item.rider.first_name +
														" " +
														item.rider.last_name +
														" (Rider)") ||
												"No rider yet"
											}
										/>
									);
								})}

						{!loading && ongoingTrips.length === 0 ? (
							<div className="gray_label bold_24px mb_35">No LIVE Trips</div>
						) : null}
					</section>

					<section className="right_section p_20 width_full flex_column_start">
						{/* Right */}
						<Map
							className="app_map"
							lat={
								cokitchens &&
								cokitchens[0] &&
								cokitchens[0].lat &&
								parseFloat(cokitchens[0].lat)
							}
							lng={
								cokitchens &&
								cokitchens[0] &&
								cokitchens[0].lng &&
								parseFloat(cokitchens[0].lng)
							}
							userLat={
								activeOngoingTrip &&
								activeOngoingTrip.calculated_order &&
								activeOngoingTrip.calculated_order.lat &&
								parseFloat(activeOngoingTrip.calculated_order.lat)
							}
							userLng={
								activeOngoingTrip &&
								activeOngoingTrip.calculated_order &&
								activeOngoingTrip.calculated_order.lng &&
								parseFloat(activeOngoingTrip.calculated_order.lng)
							}
							kitchenLat={activeOngoingTrip && activeOngoingTrip.gg}
							kitchenLng={activeOngoingTrip && activeOngoingTrip.gg}
							riderLat={
								activeRiderPos &&
								activeRiderPos.lat &&
								parseFloat(activeRiderPos.lat)
							}
							riderLng={
								activeRiderPos &&
								activeRiderPos.lat &&
								parseFloat(activeRiderPos.lng)
							}
							dispatched={
								activeOngoingTrip && activeOngoingTrip.kitchen_dispatched
							}
						/>
					</section>
				</section>

				<section className="mt_35 width_full flex_column_start bg-white p-2 overflow-scroll rounded-lg">
					<div className="flex_row_between mb_35 width_full">
						<div className="flex_row_start">
							<h6 className="bold_24px mr_10 mr_25">Trip history & feedback</h6>

							<Select
								placeholder="Locations: ALL"
								defaultValue={{ label: "Locations: ALL", value: "all" }}
								options={[{ label: "Locations: ALL", value: "all" }]}
							/>
						</div>

						<IconInput
							containerClass="half_width_desktop"
							placeholder="Search partner name, rider name or order number"
							withIcon
							icon={<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />}
							value={searchValue}
							onChange={(e) => setSearchValue(e)}
						/>
					</div>

					{/* Table start */}
					<section className="bottom_left_section width_full">
						<div className="border_bottom_thin flex_row_between">
							<p className="bold_21px mb_15">Complete trip history</p>
							{/* <Link to="#/">
                <p className="gray_label mb_15 bold">Show all</p>
              </Link> */}
						</div>

						<div className="border_bottom_thin flex_row_between py_15">
							<p className="bold_18_px">Today ({todayCompletedTrips.length})</p>
						</div>

						<table className="width_full">
							<tbody>
								<p className="gray_label bold_21px uppercase mt_25 pb_15">
									{!loading && todayCompletedTrips.length === 0
										? "No trips has been completed for today"
										: null}
								</p>
								{/* Loader */}
								{loading ? (
									<tr>
										<td>
											<div className="flex_column_left">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>
										<td>
											<div className="flex_column_center">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>

										<td>
											<div className="flex_column_left">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>

										<td>
											<div className="flex_column_right">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>
									</tr>
								) : null}
								{/* Loader End */}

								{todayCompletedTrips.map((item, i) => {
									return (
										<tr key={i + "item"}>
											<td>
												<div className="flex_column_left">
													<label className="gray_label mb_4">
														{item &&
															item.created_at &&
															moment(item.created_at).format("LT")}
													</label>
													<label className="gray_label mb_4">
														{item &&
															item.created_at &&
															moment(item.created_at).format("DD MMM YYYY")}
													</label>
													<label className="gray_label">
														{" "}
														#{item && item.order_code}
													</label>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<div className="flex_row_align_center mb_10">
														<span className="normal_53px green_text mr_10">
															•
														</span>
														<p className="normal_15px">
															From: <span className="bold">CoKitchen</span>
														</p>
													</div>

													<div className="flex_row_align_center">
														<span className="normal_53px red_text mr_10">
															•
														</span>
														<p className="normal_15px">
															To:{" "}
															<span className="bold">
																{item &&
																	item.calculated_order &&
																	item.calculated_order.address_details &&
																	item.calculated_order.address_details
																		.address_line}
															</span>
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<Clock
															fill="#939393"
															width="8px"
															height="8px"
															className="mr_15"
														/>

														<p className="normal_15px bold green_text">
															Completed
														</p>
													</div>

													<div className="flex_row_align_center">
														<HiOutlineOfficeBuilding
															style={{
																stroke: "#939393",
															}}
															width="9px"
															height="9px"
															className="mr_15"
														/>

														<p className="normal_15px">
															Bikes us logistics ltd.
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<User
															fill="#939393"
															width="9px"
															height="9px"
															className="mr_15"
														/>
														<p className="normal_15px">
															{" "}
															{item &&
																item.rider &&
																item.rider.first_name +
																	" " +
																	item.rider.last_name +
																	" (Rider)"}
														</p>
													</div>

													<div className="flex_row_align_center">
														<Stars fill="#939393" width="9px" />

														<p className="normal_15px">No comment</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<span className="bold_21px green_text mb_17">
														₦
														{item?.calculated_order?.delivery_fee &&
															numberWithCommas(
																parseInt(item.calculated_order.delivery_fee)
															)}
													</span>

													<div className="flex_row_right">
														<Link
															to={`full-trip-details/live/${
																item && item.order_code
															}`}
														>
															<p className="mr_10 normal_15px black">
																See full trip details
															</p>
														</Link>
														<Link
															to={`full-trip-details/live/${
																item && item.order_code
															}`}
														>
															<ChevronRight
																fill="#000000"
																width="11px"
																height="11px"
															/>
														</Link>
													</div>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="border_bottom_thin flex_row_between py_15">
							<p className="bold_18_px">
								Yesterday ({yesterdayCompletedTrips.length})
							</p>
						</div>
						<table className="width_full">
							<tbody>
								<p className="gray_label bold_21px uppercase mt_25 pb_15">
									{!loading && yesterdayCompletedTrips.length === 0
										? "No trips were completed for yesterday"
										: null}
								</p>

								{/* Loader */}
								{loading ? (
									<tr>
										<td>
											<div className="flex_column_left">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>
										<td>
											<div className="flex_column_center">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>

										<td>
											<div className="flex_column_left">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>

										<td>
											<div className="flex_column_right">
												<Loader
													type="ThreeDots"
													color="#ff000060"
													height={10}
													width={50}
												/>
											</div>
										</td>
									</tr>
								) : null}
								{/* Loader End */}

								{yesterdayCompletedTrips.map((item, i) => {
									return (
										<tr key={i + "item"}>
											<td>
												<div className="flex_column_left">
													<label className="gray_label mb_4">
														{item &&
															item.created_at &&
															moment(item.created_at).format("LT")}
													</label>
													<label className="gray_label mb_4">
														{item &&
															item.created_at &&
															moment(item.created_at).format("DD MMM YYYY")}
													</label>
													<label className="gray_label">
														{" "}
														#{item && item.order_code}
													</label>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<div className="flex_row_align_center mb_10">
														<span className="normal_53px green_text mr_10">
															•
														</span>
														<p className="normal_15px">
															From: <span className="bold">CoKitchen</span>
														</p>
													</div>

													<div className="flex_row_align_center">
														<span className="normal_53px red_text mr_10">
															•
														</span>
														<p className="normal_15px">
															To:{" "}
															<span className="bold">
																{item &&
																	item.calculated_order &&
																	item.calculated_order.address_details &&
																	item.calculated_order.address_details
																		.address_line}
															</span>
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<Clock
															fill="#939393"
															width="8px"
															height="8px"
															className="mr_15"
														/>

														<p className="normal_15px bold green_text">
															Completed
														</p>
													</div>

													<div className="flex_row_align_center">
														<HiOutlineOfficeBuilding
															style={{
																stroke: "#939393",
															}}
															width="9px"
															height="9px"
															className="mr_15"
														/>

														<p className="normal_15px">
															Bikes us logistics ltd.
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<User
															fill="#939393"
															width="9px"
															height="9px"
															className="mr_15"
														/>
														<p className="normal_15px">
															{" "}
															{item &&
																item.rider &&
																item.rider.first_name +
																	" " +
																	item.rider.last_name +
																	" (Rider)"}
														</p>
													</div>

													<div className="flex_row_align_center">
														<Stars fill="#939393" width="9px" />

														<p className="normal_15px">No comment</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<span className="bold_21px green_text mb_17">
														₦
														{item?.calculated_order?.delivery_fee &&
															numberWithCommas(
																parseInt(item.calculated_order.delivery_fee)
															)}
													</span>

													<div className="flex_row_right">
														<Link
															to={`full-trip-details/live/${
																item && item.order_code
															}`}
														>
															<p className="mr_10 normal_15px black">
																See full trip details
															</p>
														</Link>
														<Link
															to={`full-trip-details/live/${
																item && item.order_code
															}`}
														>
															<ChevronRight
																fill="#000000"
																width="11px"
																height="11px"
															/>
														</Link>
													</div>
												</div>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<div className="p_15_40 flex_row_center">
							<Button
								className="red_button_thick width_full"
								text={
									showAllCompletedTrips
										? `Hide full trip history  (${
												allCompletedTrips?.length || 0
										  })`
										: `Show full trip history  (${
												allCompletedTrips?.length || 0
										  })`
								}
								onClick={() => {
									setShowAllCompletedTrips(!showAllCompletedTrips);
								}}
							/>
						</div>

						{showAllCompletedTrips ? (
							<table className="width_full">
								<tbody>
									<p className="gray_label bold_21px uppercase mt_25 pb_15">
										{!loading && yesterdayCompletedTrips?.length === 0
											? "No trip has ever been completed"
											: null}
									</p>

									{/* Loader */}
									{loading ? (
										<tr>
											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
											<td>
												<div className="flex_column_center">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<Loader
														type="ThreeDots"
														color="#ff000060"
														height={10}
														width={50}
													/>
												</div>
											</td>
										</tr>
									) : null}
									{/* Loader End */}

									{allCompletedTrips?.map((item, i) => {
										return (
											<tr key={i + "item"}>
												<td>
													<div className="flex_column_left">
														<label className="gray_label mb_4">
															{item &&
																item.created_at &&
																moment(item.created_at).format("LT")}
														</label>
														<label className="gray_label mb_4">
															{item &&
																item.created_at &&
																moment(item.created_at).format("DD MMM YYYY")}
														</label>
														<label className="gray_label">
															{" "}
															#{item && item.order_code}
														</label>
													</div>
												</td>
												<td>
													<div className="flex_column_left">
														<div className="flex_row_align_center mb_10">
															<span className="normal_53px green_text mr_10">
																•
															</span>
															<p className="normal_15px">
																From: <span className="bold">CoKitchen</span>
															</p>
														</div>

														<div className="flex_row_align_center">
															<span className="normal_53px red_text mr_10">
																•
															</span>
															<p className="normal_15px">
																To:{" "}
																<span className="bold">
																	{item &&
																		item.calculated_order &&
																		item.calculated_order.address_details &&
																		item.calculated_order.address_details
																			.address_line}
																</span>
															</p>
														</div>
													</div>
												</td>

												<td>
													<div className="flex_column_left">
														<div className="flex_row_center mb_17">
															<Clock
																fill="#939393"
																width="8px"
																height="8px"
																className="mr_15"
															/>

															<p className="normal_15px bold green_text">
																Completed
															</p>
														</div>

														<div className="flex_row_align_center">
															<HiOutlineOfficeBuilding
																style={{
																	stroke: "#939393",
																}}
																width="9px"
																height="9px"
																className="mr_15"
															/>

															<p className="normal_15px">
																Bikes us logistics ltd.
															</p>
														</div>
													</div>
												</td>

												<td>
													<div className="flex_column_left">
														<div className="flex_row_center mb_17">
															<User
																fill="#939393"
																width="9px"
																height="9px"
																className="mr_15"
															/>
															<p className="normal_15px">
																{" "}
																{item &&
																	item.rider &&
																	item.rider.first_name +
																		" " +
																		item.rider.last_name +
																		" (Rider)"}
															</p>
														</div>

														<div className="flex_row_align_center">
															<Stars fill="#939393" width="9px" />

															<p className="normal_15px">No comment</p>
														</div>
													</div>
												</td>

												<td>
													<div className="flex_column_right">
														<span className="bold_21px green_text mb_17">
															₦
															{item?.calculated_order?.delivery_fee &&
																numberWithCommas(
																	parseInt(item.calculated_order.delivery_fee)
																)}
														</span>

														<div className="flex_row_right">
															<Link
																to={`full-trip-details/live/${
																	item && item.order_code
																}`}
															>
																<p className="mr_10 normal_15px black">
																	See full trip details
																</p>
															</Link>
															<Link
																to={`full-trip-details/live/${
																	item && item.order_code
																}`}
															>
																<ChevronRight
																	fill="#000000"
																	width="11px"
																	height="11px"
																/>
															</Link>
														</div>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						) : null}
					</section>
					{/* Table end */}
				</section>
			</section>
		</Layout>
	);
};

export default withAlert()(PartnerDB);
