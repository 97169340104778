import { createContext, useState } from "react";
import { getOnlyLogistics } from "../utils/superAdminStores/homeStore";

export const HomeContext = createContext();
const HomeContextProvider = ({ children }) => {
	// const [onlyLogistics, dispatch] = useReducer(
	// 	logisticsReducer,
	// 	LOGISTICS_INITIAL_STATE
	// );
	const [onlyLogistics, setOnlyLogistics] = useState([]);
	const [ordersByBrand, setOrdersByBrand] = useState([]);
	const [ordersByMeal, setOrdersByMeal] = useState([]);
	const [orderStatsByDate, setOrderStatsByDate] = useState([]);
	const [orderStatsByTIme, setOrderStatsByTime] = useState([]);
	const [orders, setOrders] = useState([]);
	const [ordersDateRange, setOrdersDateRange] = useState("");
	const [tripPage, setTripPage] = useState(2);
	const [allTrips, setAllTrips] = useState([]);
	const [userStatistics, setUserStatistics] = useState([]);
	const [allUsersData, setAllUserData] = useState([]);
	const [dataLoading, setDataLoading] =useState(false)

	// TRIPS
	const [allOngoingTrips, setAllOngoingTrips] = useState([]);
	const [allCokitchens, setAllCokitchens] = useState([]);
	const [allLogistics, setAllLogistics] = useState(null);

	return (
		<HomeContext.Provider
			value={{
				onlyLogistics,
				setOnlyLogistics,
				setOrdersByBrand,
				setOrdersByMeal,
				ordersByBrand,
				ordersByMeal,
				orderStatsByTIme,
				setOrderStatsByTime,
				orderStatsByDate,
				setOrderStatsByDate,
				orders,
				setOrders,
				ordersDateRange,
				setOrdersDateRange,
				tripPage,
				setTripPage,
				allTrips,
				setAllTrips,
				userStatistics,
				setUserStatistics,
				allUsersData,
				setAllUserData,
				allOngoingTrips,
				setAllOngoingTrips,
				allCokitchens,
				setAllCokitchens,
				allLogistics,
				setAllLogistics,
				dataLoading,
				setDataLoading
			}}
		>
			{children}
		</HomeContext.Provider>
	);
};

export default HomeContextProvider;
