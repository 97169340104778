import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import TextCard from "../../components/textCard";
import "./styles.scss";
import { withAlert } from "react-alert";
import moment from "moment";
import RateCard from "../../components/rateCard";
import AnalyticsCard from "../../components/textCard/analytics";
import DateFilter from "../../components/general/DateFilter";
import {
	getUsersData,
	getUsersStats,
} from "../../utils/superAdminStores/homeStore";
import { HomeContext } from "../../contexts/HomeContext";
const UsersPage = ({ alert }) => {
	const { userStatistics, setUserStatistics, allUsersData, setAllUserData } =
		useContext(HomeContext);
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	const [showDateRange, setShowDateRange] = useState(true);
	const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);

	const [cokitchens, setCokitchens] = useState([]);
	const [topUsersByOrders, setTopUsersByOrder] = useState([]);
	const [topUsersByPurchase, setTopUsersByPurchase] = useState([]);
	const [logisticsCopy, setLogisticsCopy] = useState(null);
	const [users, setUsers] = useState([]);
	const [usersCopy, setUsersCopy] = useState([]);
	const [bikesOnline, setBikesOnline] = useState([]);
	const [bikesOffline, setBikesOffline] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [ordersLoading, setOrdersLoading] = useState(false);
	const [statsLoading, setStatsLoading] = useState(false);
	const [currentDateRange, setCurrentDateRange] = useState([]);
	const [stats, setStats] = useState([]);
	const [userData, setUserData] = useState([]);
	const [displayDate, setDisplayDate] = useState({});
	const [todayTrips, setTodayTrips] = useState([]);
	const [yesterdayTrips, setYesterdayTrips] = useState([]);
	const [location, setLocation] = useState("");
	const [usersData, setUsersGraphData] = useState([]);
	const [sortVal, setSortVal] = useState({
		label: "Sort By: Name",
		value: "name",
	});
	const [refreshCount, setRefreshCount] = useState(0);
	const [filterCount, setFilterCount] = useState(0);
	

	const [statsValues, setStatsValues] = useState({
		males: 0,
		females: 0,
		active: 0,
		inactive: 0,
	});

	const [usersByPurchase, setUsersByPurchase] = useState([]);
	const [usersByPurchaseAll, setUsersByPurchaseAll] = useState([]);
	const [usersByOrders, setUsersByOrders] = useState([]);
	const [usersByOrdersAll, setUsersByOrdersAll] = useState([]);
	const [cashOrders, setCashOrders] = useState([]);
	const [fullPurchaseList, setFullPurchaseList] = useState([]);
	const [fullOrderList, setFullOrderList] = useState([]);
	const [graphData, setGraphData] = useState([]);
	const [graphDataAlt, setGraphDataAlt] = useState([]);
	const [graphDataStats, setGraphDataStats] = useState({ min: 0, max: 0 });
	const today = moment().format("DD-MM-YYYY");
	const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");
	// const valid = (current) => {
	//   return current.isAfter(moment(startDate));
	// };

	// load data from store
	// const loadData = async () => {
	// 	setLoading(true);
	// 	setOrdersLoading(true);
	// 	setUsers(homeStore.users);
	// 	await sortUsersByDates(homeStore.users);
	// 	await sortTrips();
	// 	setLoading(false);
	// 	await extractUsersFromOrders(homeStore.orders);
	// 	setLogisticsCopy(homeStore.orders);
	// 	setUsersCopy(homeStore.users);
	// 	setOrdersLoading(false);
	// 	await CommonStore.getCokitchens(alert);
	// 	setCokitchens(CommonStore.cokitchens);
	// 	setLoading(false);
	// };

	// load data from store

	useEffect(() => {

			const topUsers = async () => await loadTopUsersData();
			topUsers();
		
	}, [location]);

	useEffect(() => {
		setStats(userStatistics || []);
		if (userStatistics) {
			sortData(userStatistics?.data);
		}
	}, [userStatistics]);

	useEffect(() => {
		setUserData(allUsersData.data || []);
	}, [allUsersData]);
	// useEffect(async () => {
	// 	await Promise.all([ loadTopUsersData()]);
	// 	setLoading(false);
	// 	setLogisticsCopy(homeStore.orders);
	// 	setUsersCopy(homeStore.users);
	// 	setUsers(homeStore.users);
	// 	await sortTrips();
	// 	await extractUsersFromOrders(homeStore.orders);
	// 	await sortUsersByDates(homeStore.users);
	// 	sortBikers();
	// 	setCokitchens(CommonStore.cokitchens);
	// }, []);

	const sortData = (data) => {
		let byOrderList = [];
		let byPurchaseList = [];
		data
			?.sort((a, b) => b.total_amount - a.total_amount)
			.map((item) =>
				byOrderList.push({
					title: `${item.user.first_name} ${item.user.last_name}`,
					orders: item.orders_length,
					amount: `₦${numberWithCommas(item.total_amount)}`,
				})
			);

		data
			?.sort((a, b) => b.orders_length - a.orders_length)
			.map((item) =>
				byPurchaseList.push({
					title: `${item.user.first_name} ${item.user.last_name}`,
					orders: item.orders_length,
					amount: `₦${numberWithCommas(item.total_amount)}`,
				})
			);

		setFullOrderList([...byOrderList]);
		setFullPurchaseList([...byPurchaseList]);
		if (byOrderList.length >= 5) {
			byOrderList.length = 5;
		}
		if (byPurchaseList.length >= 5) {
			byPurchaseList.length = 5;
		}
		setTopUsersByOrder([...byOrderList]);
		setTopUsersByPurchase([...byPurchaseList]);

		let males = [];
		let females = [];

		data
			?.filter((item) => item.user.user_gender === "Male")
			?.map((item) => {
				males.push(item);
			});
		data
			?.filter((item) => item.user.user_gender === "Female")
			?.map((item) => {
				females.push(item);
			});
		setStatsValues({
			males: males?.length,
			females: females.length,
		});
	};

	// Trigger Refresh
	// useEffect(async () => {
	// 	if (refreshCount) {
	// 		await loadData();
	// 		setLogisticsCopy(homeStore.orders);
	// 		setUsersCopy(homeStore.users);
	// 		await sortTrips();
	// 		await extractUsersFromOrders(homeStore.orders);
	// 		await sortUsersByDates(homeStore.users);
	// 	}
	// }, [refreshCount]);

	// Filter stats by date
	// useEffect(async () => {
	// 	if (filterCount > 1) {
	// 		let i, j;

	// 		let usersCopy = homeStore?.users;

	// 		let currentTrips = [];

	// 		for (i = 0; i < usersCopy.length; i++) {
	// 			if (
	// 				moment(
	// 					moment(usersCopy[i].created_at).format("YYYY-MM-DD")
	// 				).isBetween(
	// 					moment(moment(selectionRange.startDate).subtract(1, "d")).format(
	// 						"YYYY-MM-DD"
	// 					),
	// 					moment(moment(selectionRange.endDate).add(1, "d")).format(
	// 						"YYYY-MM-DD"
	// 					)
	// 				)
	// 			) {
	// 				currentTrips.push(usersCopy[i]);
	// 			}
	// 		}
	// 		await extractUsersFromOrders(homeStore.orders);
	// 		setUsers(currentTrips);
	// 		let minMax = getMinMax(currentTrips);
	// 		const revTotal = getTotal(currentTrips) || 0;
	// 		const avrgVal = revTotal / currentTrips?.length;
	// 		setRevenue(getTotal(currentTrips));
	// 		setRevenueStats({
	// 			...revenueStats,
	// 			revenue: revTotal,
	// 			averageOrderValue: avrgVal,
	// 			highestOrderAmount: minMax[1],
	// 			lowestOrderAmount: minMax[0],
	// 		});

	// 		//sortUsersStats(currentTrips);
	// 	} else {
	// 		const revTotal = getTotal(todayTrips) || 0;
	// 		const avrgVal = revTotal / todayTrips?.length;
	// 		let minMax = getMinMax(todayTrips);
	// 		setUsers(todayTrips);
	// 		setRevenueStats({
	// 			...revenueStats,
	// 			revenue: revTotal,
	// 			averageOrderValue: avrgVal,
	// 			highestOrderAmount: minMax[1],
	// 			lowestOrderAmount: minMax[0],
	// 		});

	// 		//sortUsersStats(todayTrips);

	// 		setSelectionRange({
	// 			startDate: new Date(),
	// 			endDate: new Date(),
	// 			key: "selection",
	// 		});

	// 		await extractUsersFromOrders(homeStore.orders);
	// 	}
	// }, []);

	useEffect(() => {}, []);

	const setAllTimeData = async () => {
		let startDate = "2021-08-06";
		let endDate = moment().format("YYYY-MM-DD");
		let data = {
			start_date_range: startDate,
			end_date_range: endDate,
		};
		setStatsLoading(true);
		await getUsersStats(alert, true, data, (res) => setUserStatistics(res));
		await getUsersData(alert, true, data, (res) => setAllUserData(res));
		setCurrentDateRange({ ...data });

		setStatsLoading(false);
	};

	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const loadTopUsersData = async () => {
		let startDate = moment(selectionRange.startDate).format("YYYY-MM-DD");
		let endDate = moment(selectionRange.endDate)
			.add(1, "days")
			.format("YYYY-MM-DD");
		let data = {
			start_date_range: startDate,
			end_date_range: endDate,
			cokitchen_id:location?.id
		};
		setDisplayDate({
			start_date_range: moment(selectionRange.startDate).format("YYYY-MM-DD"),
			end_date_range: moment(selectionRange.endDate).format("YYYY-MM-DD"),
		});
		setStatsLoading(true);

		await getUsersStats(alert, true, data, (res) => setUserStatistics(res));
		await getUsersData(alert, true, data, (res) => setAllUserData(res));
		setCurrentDateRange({ ...data });

		setStatsLoading(false);
		// await homeStore.getUsersStats(alert, false, data);
		// await homeStore.getUsersData(alert, false, data);
		// setCurrentDateRange({ ...data });

		// setStats(homeStore.userStatistics || []);
		// setUserData(homeStore.userData?.data || []);

		// let res = [...homeStore.userStatistics?.data];

		// if (res) {
		// 	sortData(res);
		// 	setStatsLoading(false);
		// }
	};
	// Reducer function to compute total
	const getTotal = (items = []) => {
		let total =
			items && items[0]
				? items.reduce(function (acc, curr) {
						return (
							acc +
							parseFloat(curr?.calculated_order?.total_amount) +
							parseFloat(curr?.change || 0)
						);
				  }, 0)
				: 0;
		return total;
	};

	// Sort user stats
	const sortUsersStats = (items = []) => {
		let males = [];
		let females = [];

		let actives = [];
		let inactives = [];
		for (let i = 0; i < items.length; i++) {
			// Sort cash and card
			if (items[i]?.user_gender == "Male") {
				males.push(items[i]);
			} else if (items[i]?.user_gender == "Female") {
				females.push(items[i]);
			}
			// Sort pickup and delivery
			if (items[i]?.calculated_order?.pickup) {
				actives.push(items[i]);
			} else {
				inactives.push(items[i]);
			}
		}
		setStatsValues({
			males: males?.length,
			females: females.length,
			active: getTotal(actives),
			inactive: getTotal(inactives),
		});
	};
	// Sorts Users by purchase
	const sortUsersByPurcahse = (sortedUser = []) => {
		let currentsPurchaseUsersArr = [];
		let currentPurchaseUsersCountArr = [];
		let currentPurchaseUsersCountArrAll = [];

		let currentsOrdersUsersArr = [];
		let currentOrdersUsersCountArr = [];
		let currentOrdersUsersCountArrAll = [];
		let brandsDataArr = [
			{ color: "#E38627" },
			{ color: "#DC143C" },
			{ color: "#6A2135" },
			{ color: "#e799a3" },
			{ color: "#4cc552" },
		];
		// Sort meals orders by date
		for (let i = 0, len = sortedUser.length; i < len; i++) {
			currentsPurchaseUsersArr.push({
				userOrders: getTotal(
					sortedUser[i]?.orders?.filter((item, j) =>
						moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
							moment(moment(selectionRange.startDate).subtract(1, "d")).format(
								"YYYY-MM-DD"
							),
							moment(moment(selectionRange.endDate).add(1, "d")).format(
								"YYYY-MM-DD"
							)
						)
					)
				),
				details: sortedUser[i],
			});

			currentsOrdersUsersArr.push({
				userOrders: sortedUser[i]?.orders?.filter((item, j) =>
					moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
						moment(moment(selectionRange.startDate).subtract(1, "d")).format(
							"YYYY-MM-DD"
						),
						moment(moment(selectionRange.endDate).add(1, "d")).format(
							"YYYY-MM-DD"
						)
					)
				),
				details: sortedUser[i],
				userOrdersAmount: getTotal(
					sortedUser[i]?.orders?.filter((item, j) =>
						moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
							moment(moment(selectionRange.startDate).subtract(1, "d")).format(
								"YYYY-MM-DD"
							),
							moment(moment(selectionRange.endDate).add(1, "d")).format(
								"YYYY-MM-DD"
							)
						)
					)
				),
			});
		}

		currentsOrdersUsersArr = currentsOrdersUsersArr.sort(
			(a, b) => b?.userOrders?.length - a?.userOrders?.length
		);
		currentsPurchaseUsersArr = currentsPurchaseUsersArr.sort(
			(a, b) => b?.userOrders - a?.userOrders
		);
		// Sort meals orders by date

		let ind = 0;

		while (ind < 5) {
			currentOrdersUsersCountArr.push({
				title: currentsOrdersUsersArr[ind]?.details?.user,
				value: currentsOrdersUsersArr[ind]?.userOrders?.length,
				color: brandsDataArr[ind].color,
			});
			currentPurchaseUsersCountArr.push({
				title: currentsPurchaseUsersArr[ind]?.details?.user,
				value: currentsPurchaseUsersArr[ind]?.userOrders,
				color: brandsDataArr[ind].color,
			});

			ind++;
		}

		for (let i = 0, len = currentsOrdersUsersArr.length; i < len; i++) {
			currentOrdersUsersCountArrAll.push({
				title: currentsOrdersUsersArr[i]?.details?.user,
				orders: currentsOrdersUsersArr[i]?.userOrders?.length,
				amount: currentsOrdersUsersArr[i]?.userOrdersAmount,
				details: currentsOrdersUsersArr[i]?.details,
			});
		}
		setUsersByOrders(
			currentOrdersUsersCountArr
				.sort((a, b) => b.value - a.value)
				.filter((item) => item.value !== undefined)
		);
		setUsersByPurchase(
			currentPurchaseUsersCountArr
				.sort((a, b) => b.value - a.value)
				.filter((item) => item.value !== undefined)
		);

		currentPurchaseUsersCountArrAll = JSON.parse(
			JSON.stringify(currentOrdersUsersCountArrAll)
		);

		setUsersByPurchaseAll(
			currentPurchaseUsersCountArrAll.sort((a, b) => b.amount - a.amount)
		);
		setUsersByOrdersAll(
			currentOrdersUsersCountArrAll.sort((a, b) => b.orders - a.orders)
		);
	};

	// Get minimum and max revenues
	const getMinMax = (items = []) => {
		let min = parseFloat(
				items && items[0] && items[0].calculated_order?.total_amount
			),
			max = parseFloat(
				items && items[0] && items[0].calculated_order?.total_amount
			);

		for (let i = 1; i < items.length; i++) {
			let value = parseFloat(items[i].calculated_order?.total_amount);
			min = value < min ? value : min;
			max = value > max ? value : max;
		}
		return [min, max];
	};

	// Get minimum and max revenues for the graph
	const getMinMaxAlt = (items = []) => {
		let min = items && items[0] && items[0],
			max = items && items[0] && items[0];
		let minObj, maxObj;

		for (let i = 1; i < items?.length; i++) {
			let value = items[i];
			min =
				parseFloat(value.y) < parseFloat(min?.y || min)
					? parseFloat(value.y)
					: parseFloat(min?.y || min);
			max =
				parseFloat(value.y) > parseFloat(max?.y || max)
					? parseFloat(value.y)
					: parseFloat(max?.y || max);
		}
		for (let i = 0; i < items.length; i++) {
			if (parseFloat(items[i].y) === min) {
				minObj = items[i];
			} else if (parseFloat(items[i].y) === max) {
				maxObj = items[i];
			}
		}

		setGraphDataStats({ ...graphDataStats, min: minObj, max: maxObj });

		return [minObj, maxObj];
	};
	// Sort Revenue by date

	// Sort trips into today, yesterday and all time, cash, card
	// const sortTrips = async () => {
	// 	let todTrips = [];
	// 	let yesTrips = [];

	// 	let allTrips = homeStore?.users || [];

	// 	for (let i = 0; i < allTrips.length; i++) {
	// 		if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
	// 			todTrips.push(allTrips[i]);
	// 		} else if (
	// 			moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
	// 		) {
	// 			yesTrips.push(allTrips[i]);
	// 		}
	// 	}

	// 	//sortUsersStats(todTrips);

	// 	setUsers(todTrips);
	// 	setTodayTrips(todTrips);
	// 	setYesterdayTrips(yesTrips);
	// };

	// Sort orders into a list of their common dates
	const sortUsersByDates = (items = []) => {
		let currentGraphTrips = [];
		let allOrdersAlt =
			items &&
			items[0] &&
			Array.from(
				items &&
					items.reduce(
						(acc, o) => (
							acc.get(moment(o.created_at).format("YYYY-MM-DD")).push(o), acc
						),
						new Map(
							items &&
								items.map((o) => [
									moment(o.created_at).format("YYYY-MM-DD"),
									[],
								])
						)
					),
				([key, value]) => {
					return {
						orders: value,

						x: moment(value[0].created_at).format("DD/MM/YY"),
						y: value?.length,
						date: value[0].created_at,
						userDetail: value[0]?.calculated_order?.user,
					};
				}
			);

		let currentGraphTripsAlt = JSON.parse(
			JSON.stringify(allOrdersAlt?.sort((a, b) => b.y - a.y) || [])
		);
		setGraphData(allOrdersAlt);
		setGraphDataAlt(currentGraphTripsAlt);
		getMinMaxAlt(allOrdersAlt);
		return allOrdersAlt;
	};
	// Set current filter date
	const setFilterDate = () => {
		let currDate = "";
		if (
			moment(selectionRange?.startDate).format("DD-MM-YYYY") === today &&
			moment(selectionRange?.endDate).format("DD-MM-YYYY") === today
		) {
			currDate = "Today";
		} else if (
			moment(selectionRange?.startDate).format("DD-MM-YYYY") === yesterday &&
			moment(selectionRange?.endDate).format("DD-MM-YYYY") === yesterday
		) {
			currDate = "Yesterday";
		} else {
			currDate =
				moment(selectionRange?.startDate).format("D MMM 'YY") +
				" - " +
				moment(selectionRange?.endDate).format("D MMM 'YY");
		}

		return currDate;
	};

	// Extract users from orders
	const extractUsersFromOrders = async (items = []) => {
		items =
			items[0] &&
			items?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
		let allOrdersAlt =
			items &&
			items[0] &&
			Array.from(
				items &&
					items.reduce(
						(acc, o) => (acc.get(o?.calculated_order?.user?.id).push(o), acc),
						new Map(
							items && items.map((o) => [o?.calculated_order?.user?.id, []])
						)
					),
				([key, value]) => {
					return {
						orders: value,
						ordersLength: value?.length,
						user:
							value[0]?.calculated_order?.user?.first_name +
							" " +
							value[0]?.calculated_order?.user?.last_name,
						date: value[0]?.created_at,
						total: getTotal(value),
						userDetail: value[0]?.calculated_order?.user,
					};
				}
			);

		await setUsersGraphData(allOrdersAlt);
		await sortUsersByPurcahse(allOrdersAlt);
		return allOrdersAlt;
	};

	const handleSelect = async () => {
		let startDate = moment(selectionRange.startDate).format("YYYY-MM-DD");
		let endDate = moment(selectionRange?.endDate)
			.add(1, "days")
			.format("YYYY-MM-DD");

		let endDateDisplayed = moment(selectionRange?.endDate).format("YYYY-MM-DD");
		setDisplayDate({
			start_date_range: startDate,
			end_date_range: endDateDisplayed,
		});
		if (startDate === endDate) {
			endDate = moment(selectionRange.endDate)
				.add(1, "days")
				.format("YYYY-MM-DD");
		}
		let data = {
			start_date_range: startDate,
			end_date_range: endDate,
			cokitchen_id:location?.id
		};
		setStatsLoading(true);
		// await homeStore.getUsersStats(alert, false, data);
		// await homeStore.getUsersData(alert, false, data);
		// setCurrentDateRange({ ...data });
		// setStats(homeStore.userStatistics || []);
		// setUserData(homeStore.userData.data || []);
		// let res = homeStore.userStatistics.data;
		// sortData([...res] || []);
		// setStatsLoading(false);

		await getUsersStats(alert, true, data, (res) => setUserStatistics(res));
		await getUsersData(alert, true, data, (res) => setAllUserData(res));

		setCurrentDateRange({ ...data });

		setStatsLoading(false);

		setFilterCount((prev) => prev + 1);
	};

	// const sortBikers = () => {
	// 	let online = [];
	// 	let offLine = [];
	// 	let allRiders =
	// 		(homeStore && homeStore.logistics && homeStore.logistics.fleets) || [];
	// 	let i;
	// 	for (i = 0; i < allRiders.length; i++) {
	// 		if (allRiders[i].online) {
	// 			online.push(allRiders[i]);
	// 		} else {
	// 			offLine.push(allRiders[i]);
	// 		}
	// 	}
	// 	setBikesOnline(online);
	// 	setBikesOffline(offLine);
	// };

	let dateF = () => {
		if (displayDate.start_date_range !== displayDate.end_date_range) {
			return `${moment(displayDate.start_date_range).format(
				"DD MMM YYYY"
			)} - ${moment(displayDate.end_date_range).format("DD MMM YYYY")}`;
		} else {
			return `${moment(displayDate.start_date_range).format("DD MMM YYYY")}`;
		}
	};

	return (
		<Layout
			locationChange={setLocation}
			home="active_nav_item"
			onRefresh={() => window.location.reload()}
			refreshLoading={refreshCount && loading}
		>
			{/* Top section start */}
			<section className="flex-column w-100 d-justify start d-align-start content_section main_section">
				<section className=" flex_row_top  tab_column mb-1 w-100">
					<section className="left_section pr_25 width_full">
						<section className="top_left grid_third mb_35">
							{/* <TextCard
              cardLabel="Revenue"
              cardValue={`₦${numberWithCommas(revenueStats?.revenue || 0)}`}
              cardFooter={setFilterDate()}
              loading={loading}
            /> */}

							<TextCard
								cardLabel="Users"
								cardValue={userData?.length || "0"}
								withFooter
								cardFooter={dateF()}
								loading={statsLoading}
							/>
							<AnalyticsCard
								className="ml_15 pl_10 users-card"
								cardLabel="Top Users by Purchase"
								list={[...topUsersByPurchase] || []}
								withFooter
								modalType="purchase"
								modalBody={[...fullPurchaseList] || []}
								cardFooter={dateF()}
								loading={statsLoading}
							/>

							<AnalyticsCard
								className="ml_15_tab pl_10 users-card"
								cardLabel="Top Users by Orders"
								list={[...topUsersByOrders] || []}
								withFooter
								modalType="orders"
								modalBody={[...fullOrderList] || []}
								cardFooter={dateF()}
								loading={statsLoading}
							/>

							{/* <TextCard
								cardLabel="Highest Registration Day"
								cardValue={`${graphDataAlt[0]?.x}`}
								link={<Live />}
								withFooter
								cardFooter={"All time"}
								loading={loading}
							/>

							<TextCard
								cardLabel="Average Daily Registration"
								cardValue={`${numberWithCommas(
									parseInt(usersCopy?.length / (homeStore.dateRangeReg + 1)) ||
										0
								)}`}
								withFooter
								cardFooter={"All time"}
								loading={loading}
							/> */}
						</section>

						{/* Left middle start */}

						<div className="d-justify-start d-align-start mb-4 mt-2 flex-wrap-alt">
							{/* <RateCard
                type="pie"
                cardLabel="Top Users by Purchase"
                pieData={usersByPurchase}
                date={setFilterDate()}
                className="mr-0 mr-md-2 mb-md-0 mb-2"
                loading={ordersLoading}
                rateTwo="See full breakdown"
                footerClass="gray underline pointer"
                modalTitle={`Users by Purchase Full Breakdown:  ${setFilterDate()}`}
                modalBody={usersByPurchaseAll}
                modalType="purchase"
              />  */}

							{/* <RateCard
                type="pie"
                cardLabel="Top Users by Orders"
                pieData={usersByOrders}
                date={setFilterDate()}
                className=""
                loading={ordersLoading}
                rateTwo="See full breakdown"
                footerClass="gray underline pointer"
                modalTitle={` Users by Orders Full Breakdown:  ${setFilterDate()}`}
                modalBody={usersByOrdersAll}
                modalType="orders"
              /> */}
						</div>

						{/* Left middle end */}

						{/* Left bottom section start*/}
					</section>
					{/* Right section start */}
					<section className="right_section px_10 second_width_desktop">
						<div className="flex_row_right mb_25">
							<a
								href="#/"
								onClick={() => {
									setFilterCount((prev) => prev + 1);
									setAllTimeData();
								}}
							>
								<label className="gray_label text-white bold cursor_pointer">
									Show all time data
								</label>
							</a>
						</div>

						<DateFilter
							showDateRange={showDateRange}
							setShowDateRange={setShowDateRange}
							showDateRangeFilters={showDateRangeFilters}
							setShowDateRangeFilters={setShowDateRangeFilters}
							filterCount={filterCount}
							setFilterCount={setFilterCount}
							selectionRange={selectionRange}
							onChange={(ranges) => setSelectionRange(ranges.selection)}
							statsLoading={statsLoading}
							onClick={handleSelect}
							setSelectionRange={setSelectionRange}
						/>

						{/* Summary item */}
						<div className="flex_column"></div>
					</section>

					{/* Right section end */}
				</section>
				{/* Top setion end */}

				<div className="d-justify-start d-align-start mb-4 flex-wrap-alt w-100">
					<RateCard
						type="progressBar"
						loading={statsLoading}
						cardLabel="Males vs Female"
						className="mr-0 mr-md-2 mb-md-0 mb-2"
						date={setFilterDate()}
						rateOne={`Males (${
							parseFloat(
								(statsValues?.males /
									(statsValues?.males + statsValues?.females)) *
									100
							)
								? parseFloat(
										(statsValues?.males /
											(statsValues?.males + statsValues?.females)) *
											100
								  ).toFixed(1)
								: 0
						}%): ${numberWithCommas(statsValues?.males || 0)}`}
						rateTwo={`Females (${
							parseFloat(
								(statsValues?.females /
									(statsValues?.males + statsValues?.females)) *
									100
							)
								? parseFloat(
										(statsValues?.females /
											(statsValues?.males + statsValues?.females)) *
											100
								  ).toFixed(1)
								: 0
						}%): ${numberWithCommas(statsValues?.females || 0)}`}
						value={statsValues?.males || 0}
						maxValue={statsValues?.males + statsValues?.females}
					/>

					{/* <RateCard
            type="progressBar"
            loading={ordersLoading}
            cardLabel="Active Registered Users vs Registered New Users"
            className="mr-0 mr-md-2 mb-md-0 mb-2"
            date={setFilterDate()}
            rateOne={`Active (${
              parseInt((usersData?.length / usersCopy?.length) * 100)
                ? parseInt((usersData?.length / usersCopy?.length) * 100)
                : 0
            }%): ${numberWithCommas(usersData?.length || 0)}`}
            rateTwo={`Inactive (${
              parseInt(
                ((usersCopy?.length - usersData?.length) / usersCopy?.length) *
                  100
              )
                ? parseInt(
                    ((usersCopy?.length - usersData?.length) /
                      usersCopy?.length) *
                      100
                  )
                : 0
            }%): ${numberWithCommas(
              usersCopy?.length - usersData?.length || 0
            )}`}
            value={usersData?.length || 0}
            maxValue={usersCopy?.length || 0}
          /> */}
				</div>
				{/* 
				<div className="mb-4 w-100">
					<RateCard
						data={
							graphData &&
							graphData.sort((a, b) => new Date(a.date) - new Date(b.date))
						}
						type="graph"
						loading={false}
						cardLabel="Users Registration Rate Chart"
						align="start"
						date={"All-time"}
						rateTwo={`All-time Low (${
							graphDataStats?.min?.x
						}): ${numberWithCommas(graphDataStats?.min?.y || 0)}`}
						rateOne={`All-time High (${
							graphDataStats?.max?.x
						}): ${numberWithCommas(graphDataStats?.max?.y || 0)}`}
						value={usersData?.length}
						maxValue={usersData?.length + usersCopy?.length}
					/>
				</div> */}
				{/* Users table start */}

				{/* Left bottom section  end*/}
			</section>
		</Layout>
	);
};
export default withAlert()(UsersPage);
