import React from "react";
import { User } from "../../assets/icons/icons";
import "./styles.scss";
import { withRouter } from "react-router-dom";
import { withAlert } from "react-alert";

import Hamburger from "../hamburger";
import Logo from "../../assets/images/Logo_Dark.png";
import { Offline } from "react-detect-offline";
import { RiWifiOffLine } from "react-icons/ri";

const Header = ({
  title = "Cokitchen Logistics",
  noUser,
  history,
  alert,
  navClick,
  closed,
}) => {
  const user =
    localStorage.getItem("first_name") +
    " " +
    localStorage.getItem("last_name");
  const role = localStorage.getItem("role");
  const logout = () => {
    localStorage.clear();
    history.push("/login");
    alert.show("Logged out successfully!", {
      type: "success",
    });
  };
  return (
    <header className="header flex_row_between fixed bg-white overflow-x-scroll ">
      <figure className="flex_row">
        <Hamburger
          click={navClick}
          className="mobile hamburger_btn_alt"
          close={closed}
        />

        <img
          src={Logo}
          className="mr_30"
          alt="logo"
          style={{ width: "150px" }}
        />
      </figure>
      {!noUser && (
        <div className="flex_row">
          <div className="flex_column mr_30">
            <label className="normal_18px bold capitalize mb_5">{user}</label>
            <label className="normal_18px">
              {role == "LOGISTICS_SUPER_ADMIN"
                ? "Super Admin"
                : role == "LOGISTICS_ADMIN"
                ? "Admin"
                : ""}{" "}
            </label>
          </div>
          <span>
            <User width="28px" height="28px" fill="#ffffff" />
          </span>
          <button onClick={logout} className="cursor_pointer red_normal_20px">
            Logout
          </button>
        </div>
      )}
      <div className="position-fixed">
        <Offline polling={({ interval = 2000 }) => (interval = 2000)}>
          <div className="offline-component  position-fixed slide-left bg-gold rounded p-3 border-danger">
            <h5 className="mb-1 text-white">
              <RiWifiOffLine fill="#fff" size="25px" /> Bad Network!
            </h5>
            <p className="mb-2 text-white">
              Kindly check your internet connection and refresh.
            </p>
          </div>
        </Offline>
      </div>
    </header>
  );
};

export default withRouter(withAlert()(Header));
