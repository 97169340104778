import React from "react";
import { DateRangePicker } from "react-date-range";
import { BiSearch } from "react-icons/bi";
import Button, { IconButton } from "../buttons";
import { BsInfoCircle } from "react-icons/bs";
const DateFilter = ({
	showDateRange,
	setShowDateRange,
	showDateRangeFilters,
	setShowDateRangeFilters,
	filterCount,
	setFilterCount,
	selectionRange,
	onChange,
	statsLoading,
	onClick,
	setSelectionRange,
}) => {
	return (
		<div
			className="button red_border borderless_dates flex_row_center mb_30"
			onClick={() => {
				if (showDateRange) {
					return;
				} else {
					setShowDateRange(true);
				}
			}}
		>
			{showDateRange && (
				<div className={showDateRangeFilters ? "" : "collapsed_date_range"}>
					<div
						className="flex_row_between"
						style={{
							alignItems: "flex-start",
						}}
					>
						<button
							className="cursor_pointer mb_12 date_filter_button"
							onClick={() => setShowDateRangeFilters(!showDateRangeFilters)}
						>
							{showDateRangeFilters
								? "Hide date filters"
								: " Show date filters"}
						</button>

						<div
							style={{
								display: "flex",
								flexDirection: "column",
								// justifyContent: "flex-end",
								alignItems: "flex-end",
								gap: ".5rem",
							}}
						>
							<IconButton
								text={statsLoading ? "Loading" : "Search"}
								icon={<BiSearch />}
								withIcon
								className={"d-center "}
								isBlack
								loading={statsLoading}
								onClick={onClick}
							/>

							<p
								className="date_filter_button"
								style={{
									color: "white",
									gap: 2,
									fontSize: "12px",
									fontWeight: "bold",
									background: "rgb(61, 145, 255)",
									// opacity: 0.9,
									padding: ".3rem",
									borderRadius: "3px",
									width: "180px",
								}}
							>
								{" "}
								<BsInfoCircle />{" "}
								<span>
									After selecting the date, click here to search{" "}
								</span>{" "}
							</p>
						</div>
					</div>
					<DateRangePicker
						ranges={[selectionRange]}
						onChange={onChange}
						showMonthAndYearPickers={false}
						maxDate={new Date()}
					/>
					<div className="ml-auto d-justify-end">
						{filterCount > 0 ? (
							<button
								className="cursor_pointer mb_12 date_filter_button ml-auto d-justify-end"
								onClick={() => {
									setFilterCount(0);
									setSelectionRange({
										startDate: new Date(),
										endDate: new Date(),
										key: "selection",
									});
								}}
							>
								Clear date filter
							</button>
						) : null}
					</div>
				</div>
			)}
		</div>
	);
};

export default DateFilter;
