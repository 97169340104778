import React, { useState } from "react";
import "./styles.scss";
import Loader from "react-loader-spinner";
import Modal from "../modals";
import Select from "../select";
import { BiSearchAlt2 } from "react-icons/bi";
import IconInput from "../inputs/iconInput";
import moment from "moment";

const AnalyticsCard = ({
	modalBody,
	modalType,
	modalTitle,
	cardLabel,
	withLink,
	link,
	cardValue,
	withFooter,
	cardFooter,
	list,
	className,
	loading,
}) => {
	const [searchValue, setSearchValue] = useState("");
	const [show, setShow] = useState(false);
	const [sortVal, setSortVal] = useState({
		label: "Sort By: Default",
		value: "default",
	});
	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	return (
		<div
			className={`text_card_container flex-column d-justify-between d-align-left bg-white mt_15_tab mb_15 mr_15_tab ${className}`}
			style={{
				alignItems: "left",
				height: "auto",
			}}
		>
			<div className="flex_row_between width_full mt_15_tab   py-1">
				<label className="bold_21px bold  mb-0s ease">{cardLabel}</label>
				{withLink && link}
			</div>

			{loading ? (
				<Loader
					type="ThreeDots"
					color="#1fc900 "
					height={10}
					width={50}
					className="ml_15 d-align-center loader"
				/>
			) : (
				<div>
					{modalType === "orders"
						? list.length === 0 && (
								<span className="black bold_18px">no data to show</span>
						  )
						: list?.map((item) => {
								return (
									<div
										className="bold_21px ml_15_tab h5  green_text"
										key={item?.title}
									>
										<span className="black bold_18px">{item?.title}</span>{" "}
										{"- " + item?.orders}
									</div>
								);
						  })}

					{modalType === "purchase"
						? list.length === 0 && (
								<span className="black bold_18px">no data to show</span>
						  )
						: list?.map((item) => {
								return (
									<div
										className="bold_21px ml_15_tab h5  green_text"
										key={item?.title}
									>
										<span className="black bold_18px">{item?.title}</span>{" "}
										{"- " + item?.amount}
									</div>
								);
						  })}
				</div>
			)}

			{!loading && (
				<div className="mb_25">
					<div>
						{" "}
						<label
							className=" normal_16px  mb-1 ml_15_tab underlined cursor_pointer mb_15"
							onClick={() => {
								setShow(!show);
							}}
						>
							{list.length ===0 ? null : show ? "Show less" : "Show more"}
						</label>{" "}
					</div>
					<div>
						{" "}
						<label className="light-blue normal_16px mb-1 ml_15_tab ">
							{cardFooter}
						</label>{" "}
					</div>
				</div>
			)}

			<Modal
				showModal={show}
				closeModal={() => setShow((prev) => !prev)}
				title={modalTitle}
				content={
					modalType === "meal" ? (
						<table className="w-100">
							<thead>
								<tr>
									<th>Meals</th> <th>Number or orders</th>
								</tr>
							</thead>
							<tbody>
								{modalBody.map((item, i) => {
									return (
										<tr key={i + "Item"}>
											<td> {item.title}</td>
											<td> {item.value}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : modalType === "orders" || modalType === "purchase" ? (
						<div className="w-100 ">
							<div className="border_bottom_thin flex_row_between pb-3 w-100">
								<Select
									containerClass="w-50 mr-3"
									placeholder="Locations: ALL"
									value={sortVal}
									handleChange={(e) => setSortVal(e)}
									options={[
										{
											label: "Sort By: Default",
											value: "default",
										},
										{ label: "Sort By: Ascending orders", value: "ascending" },
										{
											label: "Sort By: Descending orders",
											value: "descending",
										},
										{ label: "Sort By: Registration Date", value: "reg" },
									]}
								/>
								<IconInput
									containerClass="w-100"
									placeholder="Search by name or number of orders"
									withIcon
									icon={
										<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />
									}
									value={searchValue}
									onChange={(e) => setSearchValue(e)}
								/>
							</div>
							<table className="w-100">
								<thead>
									<tr>
										<th>S/N</th> <th>Users</th> <th>Number of orders</th>
										<th>Total value</th>
										<th>Registration date</th>
									</tr>
								</thead>
								<tbody>
									{modalBody
										.sort((a, b) => {
											if (
												sortVal?.value === "default" &&
												modalType === "orders"
											) {
												return b.orders - a.orders;
											} else if (
												sortVal?.value === "default" &&
												modalType === "purchase"
											) {
												return b.amount - a.amount;
											} else if (sortVal?.value === "descending") {
												return parseInt(a.orders) - parseInt(b.orders);
											} else if (sortVal?.value === "ascending") {
												return parseInt(b.orders) - parseInt(a.orders);
											} else if (sortVal?.value === "reg") {
												return (
													new Date(b?.details?.userDetail?.created_at) -
													new Date(a?.details?.userDetail?.created_at)
												);
											}
										})
										.filter(
											(item) =>
												item?.title
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.orders.toString().includes(searchValue.toString())
										)
										.filter((item) => item?.orders)
										.map((item, i) => {
											return (
												<tr key={i + "Item"}>
													<td> {i + 1}</td>
													<td> {item.title}</td>
													<td> {item.orders}</td>
													<td> {item?.amount}</td>

													<td>
														{moment(
															item?.details?.userDetail?.created_at
														).format("LLLL")}
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					) : null
				}
			/>
		</div>
	);
};

export default AnalyticsCard;
