import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
	GET_LOGISTICS_URL,
	CANCEL_ORDER_URL,
	GET_EXTERNAL_REVENUE,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { LIVE_TRIPS } from "../../router/router";
	 const WALLET_API_URL =
            process.env.REACT_APP_WALLET_API_URL;
export class HomeStore {

	@observable logistics = {};
	@observable cokitchens = {};
	@observable ongoingTrips = [];
	@observable externalRevenue = [];

	@observable dataCached = false;
	@observable dataFBCached = false;
	@action updateOngoingTrips = (item) => {
		this.ongoingTrips = item;

		this.dataFBCached = true;
	};

	getLogistics = async (alert, shouldAlert, data) => {
		const { page, startDate, endDate } = data;

		try {
			let response = await dataService.getAuthData(
				GET_LOGISTICS_URL +
					`?start_time=${startDate}&end_time=${endDate}&page_no=${page}&status=ALL`
			);

			runInAction(() => {
				let res = response.data && response.data;
				this.dataCached = true;
				this.logistics = res;
				shouldAlert &&
					alert.show("Logistics data loaded successfully!", {
						type: "success",
					});
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	getExternalRevenue = async (alert, data, ) => {
		try {
			let response = await dataService.getAuthData(GET_EXTERNAL_REVENUE, {
				params: data,
				baseUrl: WALLET_API_URL
			});
			let res;
			runInAction(() => {
				res = response && response.data;
				if (res) {
					alert.show("Revenue fetched successfully!", {
						type: "success",
					});

					this.dataCached = true;

					this.externalRevenue = res;
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
			const errMsg =
				error && (error.response || error.message || error.toString() || error);
			let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
			console.log("Errror Message", errorMsg);
		}
	};

	cancelOrders = async (alert, data, history) => {
		try {
			let response = await dataService.postAuthData(CANCEL_ORDER_URL, data);
			let res;
			runInAction(() => {
				res = response && response.data;
				if (res) {
					alert.show("Order cancelled successfully!", {
						type: "success",
					});

					this.dataCached = true;

					history.push(LIVE_TRIPS);
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
			const errMsg =
				error && (error.response || error.message || error.toString() || error);
			let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
			console.log("Errror Message", errorMsg);
		}
	};
}
export default new HomeStore();
