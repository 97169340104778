import React from "react";
import { Route, Redirect } from "react-router-dom";

import { LOGIN, USERS } from "./router";
const SuperAdminRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") &&
        localStorage.getItem("role") === "SUPER_ADMIN" ? (
          <Route render={() => <Component {...props} />} />
        ) :
        
        localStorage.getItem("token") &&
        !localStorage.getItem("role") === "SUPER_ADMIN" ? 
        (
          <Redirect to={USERS} />
        )
        
        :
        
        (
          <Redirect to={LOGIN} />
        )
      }
    />
  );
};
export default SuperAdminRoute;
