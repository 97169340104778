export const externalRevenueSource = {
	FOODCOURT_APP: "Foodcourt App",
	FC_BUSINESS: "Fc Business",
	FC_EVENT_CATERING: "Fc Event Catering",
	FC_BAKERY_OVEN_FRESH: "Fc Bakery Oven Fresh",
	FC_JUICES_FRESH_PRESS: "Fc Juices fresh press",
	FC_JUICES_NECTAR: "Fc Justices Nectar",
	FC_COCKTAILS_THE_COCKTAIL_CLUB: "Fc Coktails The Cocktail club",
	CHOWDECK: "Chowdeck",
	PARTNERSHIP_AND_OTHERS: "Partnership and Others",
	FC_BUSINESS_LITE: "FC business Lite",
};
