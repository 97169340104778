import React, { useState, useEffect, useContext } from "react";
import Layout from "../../components/layout";
import TextCard from "../../components/textCard";
import Live from "../../components/live";
import "./styles.scss";
import { withAlert } from "react-alert";
import moment from "moment";
import RateCard from "../../components/rateCard";
import IconInput from "../../components/inputs/iconInput";
import { BiSearchAlt2 } from "react-icons/bi";
import Search from "./Search";
import Trips from "./Trips";
import { pieChartColors } from "../../helpers/colors";
import DateFilter from "../../components/general/DateFilter";
import { HomeContext } from "../../contexts/HomeContext";
import ExternalRevenueModal from "../../components/modals/external-revenue-modal/index";
import {
	getOnlyLogistics,
	getAdminOrders,
	getActiveUsers,
	getInternalExternalRev,
} from "../../utils/superAdminStores/homeStore";
import { useHistory } from "react-router-dom";
import dataService from "../../services/data.service";

const HomePage = ({ alert }) => {
  const navigate = useHistory();
  const {
    setOnlyLogistics,
    onlyLogistics,
    setOrdersByBrand,
    setOrdersByMeal,
    ordersByBrand,
    ordersByMeal,
    orderStatsByTIme,
    setOrderStatsByTime,
    orderStatsByDate,
    setOrderStatsByDate,
    orders,
    setOrders,
    setOrdersDateRange,
    dataLoading,
    setDataLoading,
  } = useContext(HomeContext);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [statsLoading, setStatsLoading] = useState(false);
  const [secondaryStatsLoading, setSecondaryStatsLoading] = useState(false);
  const [showDateRange, setShowDateRange] = useState(true);
  const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [applyLoading, setApplyLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [logistics, setLogistics] = useState(null);
  const [stats, setStats] = useState(null);
  const [logisticsCopy, setLogisticsCopy] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [ordersByBrandStats, setOrdersByBrandStats] = useState([]);
  const [ordersByMealStats, setOrdersByMealStats] = useState([]);
  const [topOrdersByMealStats, setTopOrdersByMealStats] = useState([]);
  const [ordersByMealStatsAll, setOrdersByMealStatsAll] = useState([]);
  const [percentages, setPercentages] = useState([]);
  const [todayTrips, setTodayTrips] = useState([]);
  const [yesterdayTrips, setYesterdayTrips] = useState([]);
  const [location, setLocation] = useState(null);
  const [refreshCount, setRefreshCount] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [allTimeRevenue, setAllTimeRevenue] = useState(0);
  const [revenueStats, setRevenueStats] = useState({
    revenue: 0,
    averageOrderValue: 0,
    highestOrderAmount: 0,
    lowestOrderAmount: 0,
  });
  const [totalRevenue, setTotalRevenue] = useState('')
  const [statsValues, setStatsValues] = useState({
    pickup: 0,
    delivery: 0,
  });
  const [currentDateRange, setCurrentDateRange] = useState([]);
  const [paymentStats, setPaymentStats] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [timeGraphData, setTimeGraphData] = useState([]);

  const [graphSecData, setSecGraphData] = useState([]);
  const [timegraphSecData, setTimeSecGraphData] = useState([]);

  const [graphDataStats, setGraphDataStats] = useState({ min: 0, max: 0 });
  const [graphSecDataStats, setGraphSecDataStats] = useState({
    min: 0,
    max: 0,
  });
  const [timeGraphDataStats, setTimeGraphDataStats] = useState({
    min: 0,
    max: 0,
  });
  const [timeGraphSecDataStats, setTimeGraphSecDataStats] = useState({
    min: 0,
    max: 0,
  });

  const [displayDate, setDisplayDate] = useState({});
  const [usersData, setUsersGraphData] = useState([]);
  const [usersSecData, setUsersSecGraphData] = useState([]);
  const [usersDataStats, setUsersGraphDataStats] = useState({ min: 0, max: 0 });
  const [usersSecDataStats, setUsersGraphSecDataStats] = useState({
    min: 0,
    max: 0,
  });
  const [retentionRate, setRetentionRate] = useState(0);
  const [revenueTrends, setRevenueTrends] = useState();
  const [loadingRevenueTrends, setLoadingRevenueTrends] = useState(false);
      const [activeUsers, setActiveUsers] = useState([]);

  const today = moment().format("DD-MM-YYYY");
  const yesterday = moment().add(-1, "days").format("DD-MM-YYYY");

  const loadRevenueData = async () => {
    const rev = await getInternalExternalRev(selectionRange);
			setTotalRevenue(rev?.data);
    const activeUserResponse = await getActiveUsers(selectionRange, location?.id)
    setActiveUsers(activeUserResponse?.data.active_users);
 }
  const loadRevenueTrends = async () => {
    try {
      setLoadingRevenueTrends(true);
      const start_date = moment(selectionRange.startDate).format("YYYY-MM-DD");
      const end_date = moment(selectionRange.endDate).add(1, 'days').format("YYYY-MM-DD");
      const params = { start_date, end_date, cokitchen_id: location?.id };
      const internal = await dataService.getAuthData("admin/internal-revenue", {
        params,
      });
      const external = await dataService.getAuthData(
        "external-revenue/internal/revenue",
        { baseUrl: process.env.REACT_APP_WALLET_API_URL, params }
      );
      const internalSources = internal.data.data;
      const externalSources = external.data.data;
      const externalDataSource = Object.entries(externalSources).map(
        ([source, total_amount]) => ({
          color:
            "#" +
            ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
          title: `${source.replace(/_/g, " ").toUpperCase()} ₦`,
          value: Math.round(total_amount),
        })
      );
      const internalDataSource = Object.entries(internalSources).map(
        ([source, total_amount]) => ({
          color:
            "#" +
            ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
          title: `${source.replace(/_/g, " ").toUpperCase()} ₦`,
          value: Math.round(total_amount),
        })
      );
      const dataSource = externalDataSource
        .concat(internalDataSource)
        .reduce((sources, source) => {
          const _source = sources.find((s) => s.title === source.title);
          if (!_source) sources.push(source);
          else _source.value = _source.value + source.value;
          return sources;
        }, []);
      setRevenueTrends(dataSource);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRevenueTrends(false);
    }
  };

  useEffect(() => {
    loadData();
    loadRevenueTrends();
    loadRevenueData()
  }, [location]);

  useEffect(() => {
    formatPercentage(onlyLogistics);
    setStats(onlyLogistics || []);
  }, [onlyLogistics]);

  useEffect(() => {
    sortBrandBarChartData();
  }, [ordersByBrand]);

  useEffect(() => {
    sortMealBarChartData();
  }, [ordersByMeal]);

  useEffect(() => {
    sortOrdersByDates(orderStatsByDate);
  }, [orderStatsByDate]);

  useEffect(() => {
    sortOrdersByTime(orderStatsByTIme);
  }, [orderStatsByTIme]);

  const sortPaymentStats = async (items = []) => {
    let cashTrips = [],
      transferTrips = [],
      cardTrips = [],
      pickupTrips = [],
      deliveryTrips = [];
    for (let i = 0, len = items.length; i < len; i++) {
      // Sort cash, card and transfer
      if (items[i]?.order_type?.name == "CASH" && !items[i]?.bank_transfer) {
        cashTrips.push(items[i]);
      } else if (
        items[i]?.order_type?.name == "CASH" &&
        items[i]?.bank_transfer
      ) {
        transferTrips.push(items[i]);
      } else {
        cardTrips.push(items[i]);
      }
      // Sort pickup and delivery
      if (items[i]?.pickup) {
        pickupTrips.push(items[i]);
      } else {
        deliveryTrips.push(items[i]);
      }
    }

    setPaymentStats([
      {
        title: "Transfer",
        value: getTotal(transferTrips.concat(cashTrips)),
        color: pieChartColors[1]?.color,
        orders: transferTrips.concat(cashTrips),
      },
      {
        title: "Card",
        value: getTotal(cardTrips),
        color: pieChartColors[2]?.color,
        orders: cardTrips,
      },
    ]);
    await setStatsValues({
      pickup: getTotal(pickupTrips),
      delivery: getTotal(deliveryTrips),
    });
  };
  const formatPercentage = (item) => {
    let deliverPercentage = item.deliveries;
    let pickupPercentage = item.pickups;
    let transferPercentage = item.transfer_orders;
    let cardPercentage = item.card_orders;
    let peerPercentage = item.thepeer_orders;
    let okraPercentage = item.okra_orders;

    setPercentages({
      delivery_percentage: deliverPercentage,
      pickup_percentage: pickupPercentage,
      transfer_percentage: transferPercentage,
      card_percentage: cardPercentage,
      peer_percentage: peerPercentage,
      okra_percentage: okraPercentage,
    });
  };

  // load data from store
  const loadData = async () => {
    setDisplayDate({
      start_date_range: moment(selectionRange.startDate).format("YYYY-MM-DD"),
      end_date_range: moment(selectionRange.endDate).format("YYYY-MM-DD"),
    });
    let data = {
      start_date_range: moment(selectionRange.startDate).format("YYYY-MM-DD"),
      end_date_range: moment(selectionRange.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD"),
      cokitchen_id: location?.id,
    };
    setLoading(true);
    setStatsLoading(true);
    setSecondaryStatsLoading(true);
    setDataLoading(true);
     
    await getOnlyLogistics(
      (res) => {
        if (res) {
          setOnlyLogistics(res);
          setDataLoading(false);
        }
      },
      alert,
      true,
      data,
      (res) => {
        if (res) {
          setOrdersByBrand(res);
        }
      },
      (res) => {
        if (res) {
          setOrdersByMeal(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByDate(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByTime(res);
        }
      },
      location && location?.id
    );
    setCurrentDateRange(data);
    setStatsLoading(false);
    setSecondaryStatsLoading(false);

    // Get all orders
    if (filterCount > 1) {
      await getAdminOrders(
        alert,
        true,
        (res) => {
          if (res) {
            setOrders(res);
          }
        },
        (res) => {
          if (res) {
            setOrdersDateRange(res);
          }
        }
      );
    }

    setLoading(false);
    setLogisticsCopy(orders);
    await sortTrips();
    await extractUsersFromOrders(orders);
    // await CommonStore.getCokitchens(alert);
    // setCokitchens(CommonStore.cokitchens);
    setLoading(false);
  };

  // Reducer function to compute total
  const getTotal = (items = []) => {
    let total =
      items && items[0]
        ? items.reduce(function (acc, curr) {
            return (
              acc +
              parseFloat(curr?.calculated_order?.total_amount) +
              parseFloat(curr?.change || 0)
            );
          }, 0)
        : 0;
    return total;
  };

  // Get minimum and max revenues
  const getMinMax = (items = []) => {
    let min = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      ),
      max = parseFloat(
        items && items[0] && items[0].calculated_order?.total_amount
      );

    for (let i = 1; i < items.length; i++) {
      let value = parseFloat(items[i].calculated_order?.total_amount);
      min = value < min ? value : min;
      max = value > max ? value : max;
    }
    return [min, max];
  };

  // Sort brand orders
  const sortBrandBarChartData = (brandOrdersCopy = ordersByBrand) => {
    let currentBrandOrdersArr = [];
    let currentBrandOrdersCountArr = [];

    // Sort brand orders by date
    for (let i = 0, len = brandOrdersCopy.length; i < len; i++) {
      currentBrandOrdersArr.push({
        brandOrders: brandOrdersCopy[i]?.orders?.filter((item, j) =>
          moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ),
        brandDetail: brandOrdersCopy[i]?.brand,
      });
    }
    for (let i = 0, len = brandOrdersCopy.length; i < len; i++) {
      currentBrandOrdersCountArr.push({
        title: `${brandOrdersCopy[i]?.brand?.name} (${brandOrdersCopy[i]?.brand?.cokitchen_name})`,
        value: brandOrdersCopy[i]?.orders_length,
        color: pieChartColors[i]?.color,
      });
    }

    setOrdersByBrandStats(currentBrandOrdersCountArr);
  };

  // Sort meal orders
  const sortMealBarChartData = (mealOrdersCopy = ordersByMeal) => {
    let currentMealOrdersArr = [];
    let currentMealOrdersCountArr = [];
    let currentMealOrdersCountArrAll = [];

    // Sort meals orders by date
    for (let i = 0, len = mealOrdersCopy.length; i < len; i++) {
      currentMealOrdersArr.push({
        mealOrders: mealOrdersCopy[i]?.orders?.filter((item, j) =>
          moment(moment(item.created_at).format("YYYY-MM-DD")).isBetween(
            moment(moment(selectionRange.startDate).subtract(1, "d")).format(
              "YYYY-MM-DD"
            ),
            moment(moment(selectionRange.endDate).add(1, "d")).format(
              "YYYY-MM-DD"
            )
          )
        ),
        mealDetail: mealOrdersCopy[i]?.meal,
      });
    }

    currentMealOrdersArr = currentMealOrdersArr.sort(
      (a, b) => b?.mealOrders?.length - a?.mealOrders?.length
    );
    // Sort meals orders by date
    for (let i = 0, len = mealOrdersCopy.length; i < len; i++) {
      currentMealOrdersCountArr.push({
        title: mealOrdersCopy[i]?.meal?.name,
        value: mealOrdersCopy[i]?.orders_length,
        color: pieChartColors[i]?.color,
      });
    }
    currentMealOrdersCountArr = currentMealOrdersCountArr?.sort(
      (a, b) => b?.value - a?.value
    );
    for (let i = 0, len = currentMealOrdersArr.length; i < len; i++) {
      currentMealOrdersCountArrAll.push({
        title: currentMealOrdersCountArr[i]?.title,
        value: currentMealOrdersCountArr[i]?.value,
      });
    }

    let topOrdersByMeal = currentMealOrdersCountArr.filter((item, idx) => {
      if (idx < 7) {
        return item;
      }
    });
    setOrdersByMealStats(currentMealOrdersCountArr);
    setTopOrdersByMealStats(topOrdersByMeal);
    setOrdersByMealStatsAll(currentMealOrdersCountArrAll);
  };

  // Get minimum and max revenues for the graph
  const getMinMaxAlt = (items = []) => {
    let min = items && items[0] && items[0],
      max = items && items[0] && items[0];
    let minObj, maxObj;

    for (let i = 0; i < items?.length; i++) {
      let value = items[i];
      min =
        parseFloat(value.y) < parseFloat(min?.y || min)
          ? parseFloat(value.y)
          : parseFloat(min?.y || min);
      max =
        parseFloat(value.y) > parseFloat(max?.y || max)
          ? parseFloat(value.y)
          : parseFloat(max?.y || max);
    }
    for (let i = 0; i < items.length; i++) {
      if (parseFloat(items[i].y) === min) {
        minObj = items[i];
      } else if (parseFloat(items[i].y) === max) {
        maxObj = items[i];
      }
    }
    return [minObj, maxObj];
  };
  // Sort Revenue by date

  // Sort trips into today, yesterday and all time, cash, card
  const sortTrips = async () => {
    let todTrips = [];
    let yesTrips = [];

    let allTrips = orders || [];

    for (let i = 0; i < allTrips.length; i++) {
      if (moment(allTrips[i].created_at).format("DD-MM-YYYY") === today) {
        todTrips.push(allTrips[i]);
      } else if (
        moment(allTrips[i].created_at).format("DD-MM-YYYY") === yesterday
      ) {
        yesTrips.push(allTrips[i]);
      }
    }
    // Sort brand and meal orders

    sortPaymentStats(todTrips);

    let minMax = await getMinMax(todTrips);

    const revTotal = (await getTotal(todTrips)) || 0;
    const allTotal = (await getTotal(allTrips || [])) || 0;
    const avrgVal = revTotal / todTrips?.length;
    setAllTimeRevenue(allTotal);
    setRevenueStats({
      ...revenueStats,
      revenue: revTotal,
      averageOrderValue: avrgVal,
      highestOrderAmount: minMax[1],
      lowestOrderAmount: minMax[0],
    });

    setRevenue(getTotal(todTrips));
    setLogistics(todTrips);
    setTodayTrips(todTrips);
    setYesterdayTrips(yesTrips);
  };

  // Sort orders into a list of their common dates
  const sortOrdersByDates = (items = []) => {
    let currentGraphTrips = [];
    let currentGraphSecTrips = [];

    let allOrdersAlt = orderStatsByDate;

    for (let i = 0; i < allOrdersAlt.length; i++) {
      let formattedNo = allOrdersAlt[i]?.revenue?.toFixed(2) || 0;
      currentGraphTrips.push({
        x: allOrdersAlt[i]?.date,
        y: formattedNo,
        date: allOrdersAlt[i]?.date,
      });

      currentGraphSecTrips.push({
        x: allOrdersAlt[i]?.date,
        y: allOrdersAlt[i]?.orders_length,
        date: allOrdersAlt[i]?.date,
      });
    }

    let minMax = getMinMaxAlt(currentGraphTrips);
    let minMaxSec = getMinMaxAlt(currentGraphSecTrips);

    setGraphData(currentGraphTrips);
    setSecGraphData(currentGraphSecTrips);

    // setGraphData(currentGraphTrips);
    // setSecGraphData(currentGraphSecTrips);
    setGraphDataStats({ ...graphDataStats, min: minMax[0], max: minMax[1] });
    setGraphSecDataStats({
      ...graphSecDataStats,
      min: minMaxSec[0],
      max: minMaxSec[1],
    });

    return currentGraphTrips;
  };

  const sortOrdersByTime = (items = []) => {
    let currentGraphTripsTime = [];
    let currentGraphSecTripsTime = [];

    let timeOrders = orderStatsByTIme;

    for (let i = 0; i < timeOrders.length; i++) {
      currentGraphTripsTime.push({
        x:
          timeOrders[i]?.time === "12-13"
            ? "12-1 pm"
            : timeOrders[i]?.time === "13-14"
            ? "1-2 pm"
            : timeOrders[i]?.time === "14-15"
            ? "2-3 pm"
            : timeOrders[i]?.time === "15-16"
            ? "3-4 pm"
            : timeOrders[i]?.time === "16-17"
            ? "4-5 pm"
            : timeOrders[i]?.time === "17-18"
            ? "5-6 pm"
            : timeOrders[i]?.time === "18-19"
            ? "6-7 pm"
            : timeOrders[i]?.time === "19-20"
            ? "7-8 pm"
            : timeOrders[i]?.time === "20-21"
            ? "8-9 pm"
            : timeOrders[i]?.time === "21-22"
            ? "9-10 pm"
            : timeOrders[i]?.time === "22-23"
            ? "10-11 pm"
            : timeOrders[i]?.time === "23-24"
            ? "11-12 pm"
            : timeOrders[i]?.time === "0-1"
            ? "12-1 am"
            : timeOrders[i]?.time === "11-12"
            ? "11-12 am"
            : timeOrders[i]?.time + " am",
        y: timeOrders[i]?.revenue?.toFixed(2) || 0,
        date: timeOrders[i]?.time,
        am: parseInt(timeOrders[i]?.time) < parseInt("12") ? "am" : "pm",
      });

      currentGraphSecTripsTime.push({
        x:
          timeOrders[i]?.time === "12-13"
            ? "12-1 pm"
            : timeOrders[i]?.time === "13-14"
            ? "1-2 pm"
            : timeOrders[i]?.time === "14-15"
            ? "2-3 pm"
            : timeOrders[i]?.time === "15-16"
            ? "3-4 pm"
            : timeOrders[i]?.time === "16-17"
            ? "4-5 pm"
            : timeOrders[i]?.time === "17-18"
            ? "5-6 pm"
            : timeOrders[i]?.time === "18-19"
            ? "6-7 pm"
            : timeOrders[i]?.time === "19-20"
            ? "7-8 pm"
            : timeOrders[i]?.time === "20-21"
            ? "8-9 pm"
            : timeOrders[i]?.time === "21-22"
            ? "9-10 pm"
            : timeOrders[i]?.time === "22-23"
            ? "10-11 pm"
            : timeOrders[i]?.time === "23-24"
            ? "11-12 pm"
            : timeOrders[i]?.time === "0-1"
            ? "12-1 am"
            : timeOrders[i]?.time === "11-12"
            ? "11-12 am"
            : timeOrders[i]?.time + " am",
        y: timeOrders[i]?.orders_length,
        date: timeOrders[i]?.time,
        am: parseInt(timeOrders[i]?.time) < parseInt("12") ? "am" : "pm",
      });
    }
    let timeminMax = getMinMaxAlt(currentGraphTripsTime);
    let timeminMaxSec = getMinMaxAlt(currentGraphSecTripsTime);

    setTimeGraphData(currentGraphTripsTime);
    setTimeSecGraphData(currentGraphSecTripsTime);

    setTimeGraphDataStats({
      ...timeGraphDataStats,
      min: timeminMax[0],
      max: timeminMax[1],
    });
    setTimeGraphSecDataStats({
      ...timeGraphSecDataStats,
      min: timeminMaxSec[0],
      max: timeminMaxSec[1],
    });
  };

  // Extract users from orders
  const extractUsersFromOrders = (items = []) => {
    let currentGraphTrips = [];
    let currentGraphSecTrips = [];
    items =
      items[0] &&
      items?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    let allOrdersAlt =
      items &&
      items[0] &&
      Array.from(
        items &&
          items.reduce(
            (acc, o) => (
              acc
                .get(
                  o?.calculated_order?.user?.first_name +
                    " " +
                    o?.calculated_order?.user?.last_name
                )
                .push(o),
              acc
            ),
            new Map(
              items &&
                items.map((o) => [
                  o?.calculated_order?.user?.first_name +
                    " " +
                    o?.calculated_order?.user?.last_name,
                  [],
                ])
            )
          ),
        ([key, value]) => {
          return {
            orders: value,
            ordersLength: value?.length,
            user:
              value[0]?.calculated_order?.user?.first_name +
              " " +
              value[0]?.calculated_order?.user?.last_name,
            date: value[0]?.created_at,
            total: getTotal(value),
          };
        }
      );

    for (let i = 0; i < allOrdersAlt?.length; i++) {
      currentGraphTrips.push({
        x:
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.first_name +
          " " +
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.last_name,
        y: getTotal(allOrdersAlt[i]?.orders),
      });

      currentGraphSecTrips.push({
        x:
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.first_name +
          " " +
          allOrdersAlt[i]?.orders[0]?.calculated_order?.user?.last_name,
        y: allOrdersAlt[i]?.orders.length,
      });
    }
    let minMax = getMinMaxAlt(currentGraphTrips);
    let minMaxSec = getMinMaxAlt(currentGraphSecTrips);
    setUsersGraphData(currentGraphTrips);
    setUsersSecGraphData(currentGraphSecTrips);

    setUsersGraphDataStats({
      ...graphDataStats,
      min: minMax[0],
      max: minMax[1],
    });
    setUsersGraphSecDataStats({
      ...graphSecDataStats,
      min: minMaxSec[0],
      max: minMaxSec[1],
    });

    calcRetentionRate(allOrdersAlt);
    return allOrdersAlt;
  };

  // Calculate retention rate

  const calcRetentionRate = (items = []) => {
    let thisweekOrders = [],
      retentionR = 0;
    for (let i = 0, len = items.length; i < len; i++) {
      if (
        moment(moment(items[i].date).format("YYYY-MM-DD")).isBetween(
          moment(moment(moment().add(-30, "days")).subtract(1, "d")).format(
            "YYYY-MM-DD"
          ),
          moment(moment().add(1, "d")).format("YYYY-MM-DD")
        )
      ) {
        thisweekOrders.push(items[i]);
      }
    }

    retentionR =
      parseFloat(
        ((thisweekOrders?.length || 0) / (items?.length || 0)) * 100
      )?.toFixed(1) || 0;
    setRetentionRate(retentionR);
  };

  const setAllTimeData = async () => {
    let startDate = "2021-08-01";
    let endDate = moment(selectionRange?.endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");

    let endDateDisplayed = moment(selectionRange?.endDate).format("YYYY-MM-DD");
    setDisplayDate({
      start_date_range: startDate,
      end_date_range: endDateDisplayed,
    });
    let data = {
      start_date_range: startDate,
      end_date_range: endDate,
      cokitchen_id: location?.id,
    };
    setStatsLoading(true);
    setCurrentDateRange({ ...data });
    setDataLoading(true);
    await getOnlyLogistics(
      (res) => {
        if (res) {
          setOnlyLogistics(res);
          setDataLoading(false);
        }
      },
      alert,
      true,
      data,
      (res) => {
        if (res) {
          setOrdersByBrand(res);
        }
      },
      (res) => {
        if (res) {
          setOrdersByMeal(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByDate(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByTime(res);
        }
      },
      location?.id
    );
    setStatsLoading(false);
    setFilterCount((prev) => prev + 1);
  };
  const navigateToExternalRevenue = () => {
    navigate.push("external-revenue");
  };

  const handleSelect = async () => {
    let startDate = moment(selectionRange?.startDate).format("YYYY-MM-DD");
    let endDate = moment(selectionRange?.endDate)
      .add(1, "days")
      .format("YYYY-MM-DD");

    let endDateDisplayed = moment(selectionRange?.endDate).format("YYYY-MM-DD");
    setDisplayDate({
      start_date_range: startDate,
      end_date_range: endDateDisplayed,
    });
    if (startDate === endDate) {
      endDate = moment(selectionRange?.endDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    let data = {
      start_date_range: startDate,
      end_date_range: endDate,
      cokitchen_id: location?.id,
    };

    setStatsLoading(true);
    setDataLoading(true);
    loadRevenueTrends();
    loadRevenueData();
    await getOnlyLogistics(
      (res) => {
        if (res) {
          setOnlyLogistics(res);
          setDataLoading(false);
        }
      },
      alert,
      true,
      data,
      (res) => {
        if (res) {
          setOrdersByBrand(res);
        }
      },
      (res) => {
        if (res) {
          setOrdersByMeal(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByDate(res);
        }
      },
      (res) => {
        if (res) {
          setOrderStatsByTime(res);
        }
      },
      location?.id
    );
    setCurrentDateRange({ ...data });
    setCurrentDateRange(data);
    setStatsLoading(false);
    setStatsLoading(false);
    setFilterCount((prev) => prev + 1);
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // const loadStoreDataToState = async () => {
  // 	setLogisticsCopy(homeStore.orders);
  // 	await sortTrips();
  // 	await sortBrandBarChartData();
  // 	await sortMealBarChartData();
  // 	await sortOrdersByDates(homeStore.orders);
  // 	await extractUsersFromOrders(homeStore.orders);
  // 	setCokitchens(CommonStore.cokitchens);
  // };

  // date
  let dateF = () => {
    if (displayDate.start_date_range !== displayDate.end_date_range) {
      return `${moment(displayDate.start_date_range).format(
        "DD MMM YYYY"
      )} - ${moment(displayDate.end_date_range).format("DD MMM YYYY")}`;
    } else {
      return `${moment(displayDate.start_date_range).format("DD MMM YYYY")}`;
    }
  };

  return (
		<Layout
			locationChange={setLocation}
			home="active_nav_item"
			options={cokitchens}
			onRefresh={() => window.location.reload()}
			refreshLoading={loading}
			applyLoading={applyLoading}
		>
			<section className="flex-column w-100 d-justify start d-align-start content_section main_section">
				<section className=" flex_row_top  tab_column mb_45 w-100">
					<section className="left_section pr_25 width_full">
						<section className="top_left grid_third mb_35">
							<TextCard
								cardLabel="Total Revenue"
								cardValue={`₦${numberWithCommas(
									Number(totalRevenue?.total_revenue)?.toFixed(2) || 0
								)}`}
								cardFooter={dateF()}
								loading={loadingRevenueTrends}
							/>
							<TextCard
								cardLabel="Internal Revenue"
								cardValue={`₦${numberWithCommas(
									Number(totalRevenue?.internal_revenue)?.toFixed(2) || 0
								)}`}
								cardFooter={dateF()}
								loading={loadingRevenueTrends}
							/>
							<TextCard
								onClick={navigateToExternalRevenue}
								cardLabel="External Revenue"
								cardValue={`${
									totalRevenue?.external_revenue
										? "₦" +
										  numberWithCommas(
												Number(totalRevenue?.external_revenue)?.toFixed(2)
										  )
										: "-"
								}`}
								cardFooter={dateF()}
								loading={loadingRevenueTrends}
							/>

							<TextCard
								cardLabel="Orders"
								cardValue={(stats && stats.orders_length) || "0"}
								withFooter
								cardFooter={dateF()}
								loading={dataLoading}
							/>
							<TextCard
								cardLabel="Average Order Value"
								cardValue={`₦${numberWithCommas(
									parseFloat(stats?.average_order_value || 0)?.toFixed(2)
								)}`}
								withFooter
								cardFooter={dateF()}
								loading={dataLoading}
							/>
							<TextCard
								cardLabel="Highest Order Amount"
								cardValue={`₦${numberWithCommas(
									stats?.highest_order_amount?.toFixed(2) || 0
								)}`}
								link={<Live />}
								withFooter
								cardFooter={dateF()}
								loading={dataLoading}
							/>
							<TextCard
								cardLabel="Lowest Order Amount"
								cardValue={`₦${numberWithCommas(
									stats?.lowest_order_amount?.toFixed(2) || 0
								)}`}
								withFooter
								cardFooter={dateF()}
								loading={dataLoading}
							/>

							{/* <TextCard
								cardLabel="Average Daily Revenue"
								cardValue={`₦${numberWithCommas(
									parseInt(allTimeRevenue / (homeStore.dateRange + 1)) || 0
								)}`}
								withFooter
								cardFooter={"All time"}
								loading={applyLoading || loading}
							/> */}
							<TextCard
								cardLabel="Average Daily Orders"
								cardValue={`${numberWithCommas(
									parseInt(stats && stats.average_daily_orders) || 0
								)}`}
								withFooter
								cardFooter={dateF()}
								loading={dataLoading}
							/>
							<TextCard
								cardLabel="Active users"
								cardValue={`${numberWithCommas(
									parseInt(activeUsers && activeUsers) || 0
								)}`}
								withFooter
								cardFooter={dateF()}
								loading={loadingRevenueTrends}
							/>

							{/* <TextCard
								cardLabel="Retention Rate"
								cardValue={retentionRate + "%"}
								withFooter
								cardFooter={"All time"}
								loading={applyLoading || loading}
							/> */}
						</section>
						{/* Left middle start */}{" "}
						<RateCard
							type="pie"
							cardLabel="Revenue by Verticals "
							loading={loadingRevenueTrends}
							pieData={revenueTrends}
							date={dateF()}
							className="mr-2"
						/>
						<div className="d-justify-start d-align-start mb-4 mt-2">
							<RateCard
								loading={dataLoading}
								type="pie"
								cardLabel="Card vs Transfer"
								pieData={[
									{
										color: "#E38627",
										title: "Card",
										value: +percentages.card_percentage,
									},
									{
										color: "#6A2135",
										title: "Transfer",
										value: +percentages.transfer_percentage,
									},
									{
										color: "#edc400",
										title: "The peer",
										value: +percentages.peer_percentage,
									},
									{
										color: "#b25690",
										title: "Okra ",
										value: +percentages.okra_percentage,
									},
								]}
								date={dateF()}
								className="mr-2"
							/>
							<RateCard
								type="pie"
								cardLabel="Pickup vs Delivery"
								pieData={[
									{
										color: "#E38627",
										title: "Pickup",
										value: +percentages.pickup_percentage,
									},
									{
										color: "#6A2135",
										title: "Delivery",
										value: +percentages.delivery_percentage,
									},
								]}
								date={dateF()}
								loading={dataLoading}
							/>
						</div>
						<div className="mb-3">
							<RateCard
								data={graphData.sort(
									(a, b) => new Date(a.date) - new Date(b.date)
								)}
								type="graph"
								loading={secondaryStatsLoading}
								cardLabel="Date Revenue Chart"
								align="start"
								date={"All-time"}
								rateTwo={`All-time Low (${
									graphDataStats?.min?.x
								}): ₦${numberWithCommas(graphDataStats?.min?.y || 0)}`}
								rateOne={`All-time High (${
									graphDataStats?.max?.x
								}): ₦${numberWithCommas(graphDataStats?.max?.y || 0)}`}
								seriesName="Revenue"
							/>
						</div>
						<div className="mb-4">
							<RateCard
								data={graphSecData?.sort(
									(a, b) => new Date(a.date) - new Date(b.date)
								)}
								type="graph"
								loading={secondaryStatsLoading}
								cardLabel="Date Orders Chart"
								align="start"
								date={"All-time"}
								rateTwo={`All-time Low (${graphSecDataStats?.min?.x}): ${
									graphSecDataStats?.min?.y || 0
								} ${graphSecDataStats?.min?.y === 1 ? "Order" : "Orders"}`}
								rateOne={`All-time High (${graphSecDataStats?.max?.x}): ${
									graphSecDataStats?.max?.y || 0
								} ${graphSecDataStats?.max?.y === 1 ? "Order" : "Orders"}`}
								seriesName="Orders"
							/>
						</div>
						<div className="mb-3">
							<RateCard
								data={timeGraphData.sort(
									(a, b) => parseInt(a?.date) - parseInt(b?.date)
								)}
								type="graph"
								loading={secondaryStatsLoading}
								cardLabel="Time Revenue Chart"
								align="start"
								date={"All-time"}
								rateTwo={`All-time Low (${
									timeGraphDataStats?.min?.x
								}): ₦${numberWithCommas(timeGraphDataStats?.min?.y || 0)} `}
								rateOne={`All-time High (${
									timeGraphDataStats?.max?.x
								}): ₦${numberWithCommas(timeGraphDataStats?.max?.y || 0)}`}
								seriesName="Revenue"
							/>
						</div>
						<div className="mb-4">
							<RateCard
								data={timegraphSecData.sort(
									(a, b) => parseInt(a?.date) - parseInt(b?.date)
								)}
								type="graph"
								loading={secondaryStatsLoading}
								cardLabel="Time Orders Chart"
								align="start"
								date={"All-time"}
								rateTwo={`All-time Low (${timeGraphSecDataStats?.min?.x})
								: ${timeGraphSecDataStats?.min?.y || 0} ${
									timeGraphSecDataStats?.min?.y === 1 ? "Order" : "Orders"
								}`}
								rateOne={`All-time High (${timeGraphSecDataStats?.max?.x}) : ${
									timeGraphSecDataStats?.max?.y || 0
								} ${timeGraphSecDataStats?.max?.y === 1 ? "Order" : "Orders"}`}
								seriesName="Orders"
							/>
						</div>
						{/* Left middle end */}
						{/* Left bottom section start*/}
						{/* Left bottom section  end*/}
					</section>

					<section className="right_section px_10 second_width_desktop">
						<div className="flex_row_right mb_25">
							<button
								onClick={() => {
									setLogistics(orders);
									setRevenue(getTotal(orders));
									setFilterCount((prev) => prev + 1);
									setAllTimeData();
									setSelectionRange({
										startDate: new Date(),
										endDate: new Date(),
										key: "selection",
									});
								}}
								className="cursor_pointer py-1 px-2 all_time_data"
							>
								Show all time data
							</button>
						</div>

						<DateFilter
							showDateRange={showDateRange}
							setShowDateRange={setShowDateRange}
							showDateRangeFilters={showDateRangeFilters}
							setShowDateRangeFilters={setShowDateRangeFilters}
							filterCount={filterCount}
							setFilterCount={setFilterCount}
							selectionRange={selectionRange}
							onChange={(ranges) => setSelectionRange(ranges.selection)}
							statsLoading={loading}
							onClick={handleSelect}
							setSelectionRange={setSelectionRange}
						/>

						{/* Summary item */}
						{/* <div className="flex_column">
							<RateCard
								isHorizontal
								type="pie"
								cardLabel="Top Brands"
								pieData={ordersByBrandStats.sort((a, b) => b.value - a.value)}
								date={dateF()}
								className="mb_17"
								loading={statsLoading}
							/>

							<RateCard
								isHorizontal
								type="pie"
								cardLabel="Top Meals"
								pieData={topOrdersByMealStats
									.sort((a, b) => b.value - a.value)
									.filter((item) => item.value !== undefined)}
								date={dateF()}
								className="mb_17"
								loading={statsLoading}
								rateTwo="See full breakdown"
								footerClass="gray underline pointer"
								modalTitle="Full Meals Order History Breakdown"
								modalBody={ordersByMealStatsAll}
								modalType="meal"
							/>
						</div> */}
					</section>
				</section>

				{/* Orders history start */}
				<section className="bottom_left_section bg-white w-100 position-relative">
					<div className="border_bottom_thin flex_row_between pb-3 w-100">
						<h5 className="mb_15">Order history</h5>
						{/* <Link
              to="#/"
              onClick={() => {
                if (showAll) {
                  setShowAll(false);
                  setShowTodayTrips(false);
                  setShowYesterdayTrips(false);
                  setShowAllTrips(false);
                } else {
                  setShowAll(true);
                  setShowTodayTrips(true);
                  setShowYesterdayTrips(true);
                  setShowAllTrips(true);
                }
              }}
            >
              <p className="gray_label mb_15 bold">
                {showAll ||
                (showAllTrips && showTodayTrips && showYesterdayTrips)
                  ? "Collapse all"
                  : "Show all"}
              </p>
            </Link>
						 <Select
              containerClass="w-30"
              placeholder="Locations: ALL"
              defaultValue={{ label: "Sort By: Name", value: "name" }}
              handleChange={(e) => setSortVal(e)}
              options={[
                { label: "Sort By: Name", value: "name" },
                { label: "Sort By: Wallet Balance", value: "balance" },
                { label: "Sort By: Registration Date", value: "reg" },
              ]}
            />  */}

						<div className="search-bar">
							<IconInput
								containerClass=""
								placeholder="Search order by code, customer/rider name or address"
								withIcon
								icon={
									<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />
								}
								value={searchValue}
								onChange={(e) => setSearchValue(e)}
							/>

							{searchValue.length > 0 && searchValue.length < 5 ? (
								<span>Enter 5 characters to start search </span>
							) : searchValue.length === 0 ? null : (
								searchValue.length > 5 && null
							)}
						</div>
					</div>

					<section
						className="bottom_left_section_alt width_full "
						style={{
							paddingBottom: "3rem",
						}}
					>
						<Search
							searchValue={searchValue}
							setSearchValue={setSearchValue}
							alert={alert}
						/>

						<Trips
							alert={alert}
							selectionRange={selectionRange}
							location={location?.id}
						/>
					</section>
				</section>
				{/* Orders history end */}
			</section>
		</Layout>
	);
};

export default withAlert()(HomePage);
