import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import ExternalRevenueCard from "../../components/externalRevenueCard";
import homeStore from "../../stores/adminStores/homeStore";
import { CommonStore } from "../../stores/superAdminStores/commonStore";
import { withAlert } from "react-alert";
import "./styles.scss";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import Button from "../../components/buttons";
import ExternalRevenueModal from "../../components/modals/external-revenue-modal";
import Loader from "react-loader-spinner";
import Paginate from "../../components/paginate";
import RateCard from "../../components/rateCard";
import dataService from "../../services/data.service";
import DateFilter from "../../components/general/DateFilter";

const ExternalRevenue = ({ alert }) => {
	const [pageCount, setPageCount] = useState(1);
	const [location, setLocation] = useState(null);
	const [loading, setLoading] = useState(false);
	const [cokitchens, setCokitchens] = useState([]);
	const [showDateRange, setShowDateRange] = useState(true);
	const [showDateRangeFilters, setShowDateRangeFilters] = useState(false);
	const [activeRevenue, setActiveRevenue] = useState();
	const [filterCount, setFilterCount] = useState(0);
	const [data, setData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [selectionRange, setSelectionRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});
	const [revenueTrends, setRevenueTrends] = useState([]);
	const [loadingRevenueTrends, setLoadingRevenueTrends] = useState(false);

	const loadRevenueTrends = async () => {
		try {
			const start_date = moment(selectionRange.startDate).format("YYYY-MM-DD");
			const end_date = moment(selectionRange.endDate)
				.add(1, "days")
				.format("YYYY-MM-DD");
			const params = { start_date, end_date };
			setLoadingRevenueTrends(true);
			const res = await dataService.getAuthData(
				"external-revenue/internal/revenue",
				{ baseUrl: process.env.REACT_APP_WALLET_API_URL, params }
			);
			const dataSource = Object.entries(res.data.data).map(
				([source, total_amount]) => ({
					color:
						"#" +
						((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0"),
					title: `${source.replace(/_/g, " ").toUpperCase()} ₦`,
					value: Math.round(total_amount),
				})
			);
			setRevenueTrends(dataSource);
		} catch (err) {
			console.log(err);
		} finally {
			setLoadingRevenueTrends(false);
		}
	};

	const loadData = async () => {
		setLoading(true);
		try {
			await homeStore.getExternalRevenue(alert, {
				start_date: moment(selectionRange.startDate).format("YYYY-MM-DD"),
				end_date: moment(selectionRange.endDate)
					.add(1, "days")
					.format("YYYY-MM-DD"),
				page: pageCount,
				size: 15,
				cokitchen_id: location?.id,
			});
			const localData = homeStore.externalRevenue;
			setData(localData);
			console.log();
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		loadData();
	}, [location, pageCount]);
	useEffect(() => {
		loadRevenueTrends();
	}, [location]);

	const handleSelect = () => {
		loadData();
		loadRevenueTrends();
	};
	return (
		<Layout
			locationChange={setLocation}
			options={cokitchens}
			home="active_nav_item"
			onRefresh={() => window.location.reload()}
			refreshLoading={loading}
			applyLoading={loading}
		>
			<div>
				<div className="w-100 create_button">
					<Button
						className=" "
						text={"Create revenue"}
						onClick={() => {
							setShowModal(!showModal);
						}}
					/>
				</div>

				<section className="main_containers">
					<div class="sub_container">
						<RateCard
							type="pie"
							cardLabel="Revenue by Verticals "
							loading={loadingRevenueTrends}
							pieData={revenueTrends}
							className="mr-2"
						/>
						<div className="container">
							{loading ? (
								<div className="center_loader">
									<Loader
										type="ThreeDots"
										color="#939393 "
										height={10}
										width={50}
									/>
								</div>
							) : data.data?.length === 0 ? (
								<div className="">
									<p className="bold_white_23">No external revenue for this date period</p>
								</div>
							) : (
								data?.data?.map((revenue, i) => (
									<div key={i}>
										<ExternalRevenueCard
											onClick={() => {
												setActiveRevenue(revenue);
												setShowModal(true);
											}}
											onDelete={()=>setData({...data, data: data.data.filter((item)=> item.id !== revenue.id)})}
											revenue={revenue}
										/>
									</div>
								))
							)}
						</div>
					</div>

					<section className="">
						<div className="flex_row_right mb_25">
							<a href="#/">
								<label className="gray_label bold cursor_pointer">
									Show all time data
								</label>
							</a>
						</div>

						<DateFilter
							showDateRange={showDateRange}
							setShowDateRange={setShowDateRange}
							showDateRangeFilters={showDateRangeFilters}
							setShowDateRangeFilters={setShowDateRangeFilters}
							filterCount={filterCount}
							setFilterCount={setFilterCount}
							selectionRange={selectionRange}
							onChange={(ranges) => setSelectionRange(ranges.selection)}
							statsLoading={loading}
							onClick={handleSelect}
							setSelectionRange={setSelectionRange}
						/>
					</section>
				</section>
				<Paginate
					pageCount={Math.ceil(
						data?.pagination?.total / data?.pagination?.size
					)}
					onPageChange={(e) => setPageCount?.(e.selected + 1)}
				/>
				{showModal && (
					<ExternalRevenueModal
						show={showModal}
						closeModal={() => setShowModal(false)}
						edit={activeRevenue}
						initialFormState={activeRevenue}
						onRefresh={loadData}
					/>
				)}
			</div>
		</Layout>
	);
};

export default withAlert()(ExternalRevenue);
