const pieChartColors = [
	{ color: "#10a37e" },
	{ color: "#a5169c" },
	{ color: "#8671f8" },
	{ color: "#a9b8d5" },
	{ color: "#9ce4f0" },
	{ color: "#b342ee" },
	{ color: "#1d12ce" },
	{ color: "#ad87f0" },
	{ color: "#11137f" },
	{ color: "#c9899e" },
	{ color: "#2dbd1b" },
	{ color: "#346acb" },
	{ color: "#c185d1" },
	{ color: "#4ca6e5" },
	{ color: "#2927b6" },
	{ color: "#1188d2" },
	{ color: "#5f114f" },
	{ color: "#c7feea" },
	{ color: "#bf9479" },
	{ color: "#18eb67" },
	{ color: "#0b37f5" },
	{ color: "#1ff923" },
	{ color: "#e3bab9" },
	{ color: "#101ebb" },
	{ color: "#2ca1ab" },
	{ color: "#21747e" },
	{ color: "#4494c8" },
	{ color: "#21fd1e" },
	{ color: "#6705ab" },
	{ color: "#4afd67" },
	{ color: "#c6ff98" },
	{ color: "#22ce42" },
	{ color: "#0a8e40" },
	{ color: "#521f46" },
	{ color: "#ad185a" },
	{ color: "#b4c283" },
	{ color: "#45652b" },
	{ color: "#b09710" },
	{ color: "#9221ec" },
	{ color: "#61c0fc" },
	{ color: "#4b444a" },
	{ color: "#5d6df4" },
	{ color: "#03bd05" },
	{ color: "#23e317" },
	{ color: "#2b0853" },
	{ color: "#6de053" },
	{ color: "#b86964" },
	{ color: "#3a6d76" },
	{ color: "#2ddc4f" },
	{ color: "#5725bf" },
	{ color: "#0b8176" },
	{ color: "#4a34df" },
	{ color: "#2ccd5f" },
	{ color: "#4cdad6" },
	{ color: "#80b091" },
	{ color: "#c28afe" },
	{ color: "#7aa21e" },
	{ color: "#3842b4" },
	{ color: "#47d176" },
	{ color: "#cda27d" },
	{ color: "#1739cb" },
	{ color: "#ddb1f0" },
	{ color: "#e62f44" },
	{ color: "#88c30d" },
	{ color: "#b5df01" },
	{ color: "#8f3e5e" },
	{ color: "#58102d" },
	{ color: "#532530" },
	{ color: "#025bf9" },
	{ color: "#d4dfe6" },
	{ color: "#fcf7ec" },
	{ color: "#dde4a5" },
	{ color: "#e52c9e" },
	{ color: "#ead06b" },
	{ color: "#b5cacb" },
	{ color: "#3797c3" },
	{ color: "#8fe8f1" },
	{ color: "#7cc3f3" },
	{ color: "#93b5fd" },
	{ color: "#049330" },
	{ color: "#5d1584" },
	{ color: "#c0eec1" },
	{ color: "#58381c" },
	{ color: "#53f8eb" },
	{ color: "#215cde" },
	{ color: "#06b7f5" },
	{ color: "#1cd668" },
	{ color: "#ff42fb" },
	{ color: "#0bedbc" },
	{ color: "#396640" },
	{ color: "#fc49e9" },
	{ color: "#53708b" },
	{ color: "#d4da3e" },
	{ color: "#b1e7f0" },
	{ color: "#384269" },
	{ color: "#cef964" },
	{ color: "#9b2531" },
	{ color: "#c54db5" },
	{ color: "#481658" },
	{ color: "#728d4e" },
	{ color: "#b363c4" },
	{ color: "#0bc2a5" },
	{ color: "#cd6142" },
	{ color: "#e323ee" },
	{ color: "#3d06d5" },
	{ color: "#f1e0f8" },
	{ color: "#70cd44" },
	{ color: "#d1d201" },
	{ color: "#f0ed2f" },
	{ color: "#2636c8" },
	{ color: "#c04179" },
	{ color: "#756748" },
	{ color: "#6c3861" },
	{ color: "#46cf70" },
	{ color: "#8b3bb0" },
	{ color: "#163b36" },
	{ color: "#310c42" },
	{ color: "#f487b0" },
	{ color: "#9e5f8f" },
	{ color: "#93686a" },
	{ color: "#c4f5e9" },
	{ color: "#f94d8c" },
	{ color: "#72932f" },
	{ color: "#2a80fa" },
	{ color: "#1a6590" },
	{ color: "#582d39" },
	{ color: "#d6ec84" },
	{ color: "#20e397" },
	{ color: "#c2e145" },
	{ color: "#a15f36" },
	{ color: "#471647" },
	{ color: "#4ac3d0" },
	{ color: "#89ce15" },
	{ color: "#5c8770" },
	{ color: "#6f282e" },
	{ color: "#307953" },
	{ color: "#078f78" },
	{ color: "#f0ffbb" },
	{ color: "#0778fa" },
	{ color: "#0c75c7" },
	{ color: "#71e623" },
	{ color: "#bbad6b" },
	{ color: "#f901aa" },
	{ color: "#e48d33" },
	{ color: "#ab9627" },
	{ color: "#8af1b8" },
	{ color: "#636e89" },
	{ color: "#e9bd7d" },
	{ color: "#d652e0" },
	{ color: "#3aee6e" },
	{ color: "#ac52ca" },
	{ color: "#2d68d7" },
	{ color: "#cebdb5" },
	{ color: "#ff6f4c" },
	{ color: "#faecaf" },
	{ color: "#401319" },
	{ color: "#bc9ccb" },
	{ color: "#e1504e" },
	{ color: "#5cd7fa" },
	{ color: "#88f337" },
	{ color: "#00de5e" },
	{ color: "#c2f396" },
	{ color: "#cbeec8" },
	{ color: "#194d80" },
	{ color: "#e4ceb7" },
	{ color: "#054bbf" },
	{ color: "#287d9f" },
	{ color: "#f29e9d" },
	{ color: "#257a5d" },
	{ color: "#117772" },
	{ color: "#e9940a" },
	{ color: "#05f6e5" },
	{ color: "#bcb4f7" },
	{ color: "#36152b" },
	{ color: "#239ee8" },
	{ color: "#9c2cd4" },
	{ color: "#d350f6" },
	{ color: "#c04f55" },
	{ color: "#081d64" },
	{ color: "#f003a7" },
	{ color: "#810e0b" },
	{ color: "#6a627f" },
	{ color: "#6cf69e" },
	{ color: "#a93781" },
	{ color: "#03c25f" },
	{ color: "#da913d" },
	{ color: "#c0d5da" },
	{ color: "#a0f61f" },
	{ color: "#a8907f" },
	{ color: "#b794ee" },
	{ color: "#2a5d56" },
	{ color: "#a65440" },
	{ color: "#2265c8" },
	{ color: "#c2378c" },
	{ color: "#ec4dd2" },
	{ color: "#0d38ee" },
	{ color: "#4bf5a6" },
	{ color: "#bc1d17" },
	{ color: "#856320" },
	{ color: "#87a49c" },
	{ color: "#fbf381" },
	{ color: "#dd716f" },
	{ color: "#e383c9" },
	{ color: "#8a9996" },
	{ color: "#bd4593" },
	{ color: "#e98484" },
	{ color: "#4f7319" },
	{ color: "#33e52e" },
	{ color: "#154a33" },
	{ color: "#08a0a3" },
	{ color: "#5fc204" },
	{ color: "#954daf" },
	{ color: "#a8dc7b" },
	{ color: "#6f2f85" },
	{ color: "#1df36e" },
	{ color: "#13131f" },
	{ color: "#ac663c" },
	{ color: "#eed7dd" },
	{ color: "#0999e1" },
	{ color: "#67941f" },
	{ color: "#534a77" },
	{ color: "#4f7fde" },
	{ color: "#6c9fca" },
	{ color: "#c03983" },
	{ color: "#428bae" },
	{ color: "#83195a" },
	{ color: "#1d1cd2" },
	{ color: "#e2d958" },
	{ color: "#ca0555" },
	{ color: "#bcf0a8" },
	{ color: "#d6beda" },
	{ color: "#30e4f0" },
	{ color: "#2bcfcc" },
	{ color: "#f130b5" },
	{ color: "#b3a120" },
	{ color: "#7834be" },
	{ color: "#1ec5fe" },
	{ color: "#db4091" },
	{ color: "#aa8d39" },
	{ color: "#0d2be5" },
	{ color: "#688c2b" },
	{ color: "#af5c4e" },
	{ color: "#c0bac4" },
	{ color: "#033426" },
	{ color: "#7bd709" },
	{ color: "#3db731" },
	{ color: "#90a0bf" },
	{ color: "#4c3544" },
	{ color: "#55589f" },
	{ color: "#dd54c6" },
	{ color: "#3c3936" },
	{ color: "#1d0150" },
	{ color: "#51ec4a" },
	{ color: "#73c435" },
	{ color: "#f60bdc" },
	{ color: "#ef47c9" },
	{ color: "#36a3a9" },
	{ color: "#568b66" },
	{ color: "#5c848e" },
	{ color: "#d2f848" },
	{ color: "#84d7bc" },
	{ color: "#448bc6" },
	{ color: "#2e387c" },
	{ color: "#2b6007" },
	{ color: "#f11bb2" },
	{ color: "#2eaf69" },
	{ color: "#5814d1" },
	{ color: "#327446" },
	{ color: "#2e7399" },
	{ color: "#02d45a" },
	{ color: "#6c0979" },
	{ color: "#95bc3e" },
	{ color: "#234ebf" },
	{ color: "#5f257d" },
	{ color: "#b0fbc4" },
	{ color: "#23c574" },
	{ color: "#039062" },
	{ color: "#bc3b87" },
	{ color: "#105ba9" },
	{ color: "#a63d71" },
	{ color: "#5c9219" },
	{ color: "#dcf61b" },
	{ color: "#31d0e4" },
	{ color: "#1369b0" },
	{ color: "#a9209e" },
	{ color: "#31511b" },
	{ color: "#ff2e0c" },
	{ color: "#c67dab" },
	{ color: "#a35223" },
	{ color: "#ece11e" },
	{ color: "#f3d6ce" },
	{ color: "#dc55aa" },
	{ color: "#b653f0" },
	{ color: "#44c311" },
	{ color: "#e91891" },
	{ color: "#a304c5" },
	{ color: "#ccbd80" },
	{ color: "#09ed86" },
	{ color: "#061489" },
	{ color: "#0683da" },
];

export { pieChartColors };
