import { useState, useEffect, useCallback, useContext } from "react";
import dataService from "../services/data.service";
import { GET_LOGISTICS_URL } from "./urls";
import errorHandler from "./errorHandler";
import moment from "moment";
import { HomeContext } from "../contexts/HomeContext";

const useInfiniteLoad = (selectionRange,location, page, alert) => {
	const { allLogistics } = useContext(HomeContext);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(false);
	const [logisticsData, setLogisticsData] = useState(allLogistics);
	const [errorEncountered, setErrorEncountered] = useState(false);
	const [list, setList] = useState([]);
	const [todayStart, setTodayStart] = useState([]);
	const [yesterdayStart, setYesterdayStart] = useState([]);

	const sendQuery = useCallback(async () => {
		try {
			await setIsLoading(true);
			await setError(false);
			const response =
				page >= 1 &&
				(await dataService.getAuthData(
					GET_LOGISTICS_URL +
						`?start_time=${moment(selectionRange.startDate)
							.subtract(1, "days")
							.toISOString()}&end_time=${moment(
							selectionRange.endDate
						).toISOString()}&page_no=${page}&status=COMPLETED${location ? `&cokitchen_id=${location}`:''}`
				));
			setLogisticsData(response?.data?.data.results);
			let res = response?.data?.data?.results;

			setList(res);

			setIsLoading(false);

			setErrorEncountered(false);
		} catch (error) {
			errorHandler(error, alert);
			// setError(err);
			setErrorEncountered(true);
		}
	}, [page, location]);

	useEffect(() => {
		sendQuery();
	}, [sendQuery, page, selectionRange]);

	return {
		isLoading,
		error,
		list,
		todayStart,
		yesterdayStart,
		errorEncountered,
		logisticsData,
	};
};

export default useInfiniteLoad;
