import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/header";
import { ArrowBack, Clock, User, Stars } from "../../assets/icons/icons";
import OrderCard from "../../components/orderCard";
import { useHistory } from "react-router-dom";
import {
	HOME_S_ADMIN,
	LIVE_TRIPS,
	NOTIFICATIONS_S_ADMIN,
} from "../../router/router";
import Map from "../../components/map";
import { withAlert } from "react-alert";
import moment from "moment";
import "./styles.scss";
import { numberWithCommas } from "../../helpers/commaSeparator";
import { HomeContext } from "../../contexts/HomeContext";
import { getLogistics } from "../../utils/superAdminStores/homeStore";
const MapPage = ({ alert, ...props }) => {
	const { allTrips, allLogistics, setAllLogistics, orders, setOrders } =
		useContext(HomeContext);
	const history = useHistory();
	const path = props.match.params.id;
	const pathType = props.match.params.type;
	const [logistics, setLogistics] = useState(null);
	const [details, setDetails] = useState(null);

	// load data from store
	useEffect(() => {
		const fetchLogistics = async () =>
			await getLogistics(alert, true, (res) => setAllLogistics(res));

		fetchLogistics();
	}, []);

	useEffect(() => {
		setLogistics(allLogistics);

		findCurrentTrip();
	}, [allLogistics]);

	// FInd currently selected trip
	const findCurrentTrip = async (allTrips = (orders[0] && orders) || []) => {
		let active = await allTrips.find((item) => item.order_code === path);
		setDetails(active);
		return active;
	};

	// Reducer function to compute total
	const getTotal = (items) => {
		let total = items.reduce(function (acc, curr) {
			return acc + curr.quantity;
		}, 0);
		return total;
	};

	// To calculate the total number of items bought
	const getItemsTotal = (orders) => {
		let i;
		let tot = [];
		for (i = 0; i < orders.length; i++) {
			tot.push(getTotal(orders[i].meals));
		}
		let allTotals = tot.reduce((acc, curr) => {
			return acc + curr;
		}, 0);

		return allTotals;
	};

	return (
		<main className="main_container">
			<div className="main_container_content">
				<Header />
				<section className="content_section mt-5 pt-3">
					<div className="main_section_sec p_40">
						<div className="flex_row_between title_bar">
							<div className="flex_row_center">
								<button
									className="mr_45 cursor_pointer"
									onClick={() => {
										if (pathType === "home") {
											history.push(HOME_S_ADMIN);
										} else if (pathType === "notification") {
											history.push(NOTIFICATIONS_S_ADMIN);
										} else {
											history.push(LIVE_TRIPS);
										}
									}}
								>
									<ArrowBack width="17px" height="17px" fill="#FF0000" />
								</button>

								<h6 className="bold_24px mr_10">#{path} </h6>

								<label className="gray normal_14px">
									{moment(details?.created_at).format("LT")} |{" "}
									{moment(details?.created_at).format("DD MMM YYYY")}
								</label>
							</div>
						</div>
						<section className="top_section_sec flex_row_between ml_75 mobile_column_wide">
							<div className="flex_column_left">
								<div className="flex_row_align_center">
									<span className="normal_53px green_text mr_10">•</span>
									<p className="normal_15px">
										From: <span className="bold">CoKitchen Lekki </span>
									</p>
								</div>

								<div className="flex_row_align_center">
									<span className="normal_53px red_text mr_10">•</span>
									<p className="normal_15px">
										To:{" "}
										<span className="bold">
											{details?.calculated_order?.address_details?.address_line}
										</span>
									</p>
								</div>
							</div>
							{/* top middle */}

							<div className="flex_column_left">
								<div className="flex_row_align_center mb_15">
									<span className="mr_10">
										<Clock
											fill=" rgba(0, 0, 0, 0.214)"
											width="7px"
											height="7px"
										/>
									</span>
									<p className="normal_15px bold green_text">Completed</p>
								</div>

								<div className="flex_row_align_center">
									<span className="mr_10">
										<User
											fill="rgba(0, 0, 0, 0.214)"
											width="7px"
											height="7px"
										/>
									</span>
									<p className="normal_15px">
										{(details &&
											details.rider &&
											details.rider.first_name +
												" " +
												details.rider.last_name +
												" (Rider)") ||
											"No rider yet"}
									</p>
								</div>
							</div>

							{/* top last */}

							<div className="flex_column_right mobile_column_wide">
								<h6 className="bold_24px green_text mb_15">
									₦{" "}
									{details?.calculated_order?.delivery_fee &&
										numberWithCommas(
											parseInt(details.calculated_order.delivery_fee)
										)}
								</h6>
								<label className="normal_15px text_right">
									Total trip time: 12mins <br />
									Total trip distance: 2km
								</label>
							</div>
						</section>

						<section className="flex_row_top ml_35_0_0_75 tab_column">
							<section className="left_section_sec flex_column_left">
								{/* <p className="bold_21px mb_25">User rating & feedback</p>
                <div className="mb_35">
                  <Stars /> <span className="normal_px_20 ml_10">4/5</span>
                </div> */}

								{/* <p className="bold_21px mb_25">Improvements requested</p>

                <div className="flex_column_left mb_15">
                  <div className="flex_row_start mb_17">
                    <IoMdFlash
                      fill="#C4C4C4"
                      width="7px"
                      height="7px"
                      className="mr_15"
                    />
                    <p className="normal_15px">Faster delivery</p>
                  </div>
                  <div className="flex_row_start mb_17">
                    <AiTwotoneDislike
                      fill="#C4C4C4"
                      width="7px"
                      height="7px"
                      className="mr_15"
                    />
                    <p className="normal_15px">Rider behaviour</p>
                  </div>
                  <div className="flex_row_start mb_17">
                    <IoBagHandle
                      fill="#C4C4C4"
                      width="7px"
                      height="7px"
                      className="mr_15"
                    />
                    <p className="normal_15px">Food handling</p>
                  </div>
                </div> */}

								{/* <p className="bold_21px mb_25">Comment</p>
                <p className="normal_15px mb_45">
                  Rider was not so good but apologised saying he had a bad day.
                </p> */}
								{/* Order detail */}
								<div className="order_detail_table flex_column_left">
									<div className="flex_row_between mb_25">
										<p className="bold_21px mr_75">Order details</p>
										<div className="flex_column_right">
											<label className="bold_21px mb_12">
												{(details?.calculated_order?.meals?.length > 1 &&
													details.calculated_order.meals.length + " brands") ||
													details?.calculated_order?.meals?.length + " brand"}
												,
												{`${
													details?.calculated_order?.meals &&
													getItemsTotal(details?.calculated_order?.meals)
												} ${
													details?.calculated_order?.meals &&
													getItemsTotal(details?.calculated_order?.meals) === 1
														? "item"
														: "items"
												}`}
											</label>
											<label className="bold_21px mb_12">
												Order total: ₦{" "}
												{details?.calculated_order?.total_amount &&
													numberWithCommas(
														details.calculated_order.total_amount
													)}
											</label>
											<label className="bold_21px mb_12 green_text">
												₦
												{details?.calculated_order?.total_amount &&
													numberWithCommas(
														details.calculated_order.total_amount
													)}{" "}
												{details?.order_type?.name === "CASH"
													? " Cash Received"
													: "Card"}
											</label>
											{/* <label className="bold_21px mb_12">
                        Change to customer wallet: ₦400
                      </label> */}
										</div>
									</div>

									{details?.calculated_order?.meals.map((item, i) => {
										return (
											<OrderCard
												title={item && item.brand && item.brand.name}
												items={item && item.meals}
												key={"item" + i}
											/>
										);
									})}
								</div>
							</section>
							<section className="right_section_sec map_section flex_column_left">
								<div className="flex_row_between map_label">
									<p className="mb_25 bold_21px">Trip route </p>
								</div>

								<Map
									className="app_map app_map_detail"
									// lat={
									//   cokitchens &&
									//   cokitchens[0] &&
									//   cokitchens[0].lat &&
									//   parseFloat(cokitchens[0].lat)
									// }
									// lng={
									//   cokitchens &&
									//   cokitchens[0] &&
									//   cokitchens[0].lng &&
									//   parseFloat(cokitchens[0].lng)
									// }
									userLat={
										details?.calculated_order?.lat &&
										parseFloat(details.calculated_order.lat)
									}
									userLng={
										details?.calculated_order?.lng &&
										parseFloat(details.calculated_order.lng)
									}
									kitchenLat={details && details.gg}
									kitchenLng={details && details.gg}
								/>

								<div className="p_20 mt_35 card flex_column_start width_full">
									<p className="mb_30 bold_21px">User information </p>
									<div className="flex_row_between tab_column width_full">
										<p className="normal_15px bold mb_15">
											Name:{" "}
											{details?.calculated_order?.user?.first_name +
												" " +
												details?.calculated_order?.user?.last_name}
										</p>
										<p className="normal_15px bold mb_15">
											Email: {details?.calculated_order?.user?.email}
										</p>
									</div>

									<div className="flex_row_between tab_column width_full mb_35">
										<p className="normal_15px bold mb_15">
											Phone:{details?.calculated_order?.user?.phone_number}
										</p>
										<p className="normal_15px bold mb_15">
											user since:{" "}
											{details?.calculated_order?.user?.created_at &&
												moment(details.calculated_order.user.created_at).format(
													"DD/MM/YYYY"
												)}
										</p>
									</div>
								</div>
							</section>
						</section>
					</div>
				</section>
			</div>
		</main>
	);
};
export default withAlert()(MapPage);
