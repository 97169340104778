// Super Admin
export const CREATE_PARTNERS_URL =
	"logistics/super-admin/register-all-logistics";
export const GET_PARTNERS_URL = "logistics/super-admin/delivery-partner";
export const UPDATE_PAYMENTS_URL = "logistics/super-admin/delivery-partner/pay";
export const DELETE_PARTNER_URL = "logistics/super-admin/logistics-company/";
export const PAYMENT_HISTORY_URL = "logistics/super-admin/transactions";
export const SEARCH_ORDER_URL = "logistics/super-admin/order/search/";
export const GET_EXTERNAL_REVENUE =
	"external-revenue/internal/revenue_transactons";
// Cokitchens
export const GET_COKITCHENS_URL = "internal/cokitchen";
export const GET_COKITCHENS = "internal/all/cokitchen";

// Logistics
export const GET_LOGISTICS_URL = "orders/history"
export const GET_LOGISTICS_URL_ONLY = "admin/get-order-stats-for-admin";
export const CANCEL_ORDER_URL = "logistics/super-admin/order/cancel";
// Admin Home

export const GET_ORDERS_URL = "admin/orders";
export const GET_ORDERS_BY_BRAND_URL = "admin/orders/by/brand";
export const GET_ORDERS_BY_MEAL_URL = "admin/orders/by/meal";
export const GET_BRAND_ORDERS_BY_DATE = "admin/get-brand-order-stats-for-admin";
export const GET_MEAL_ORDERS_BY_DATE = "admin/get-meal-order-stats-for-admin";
export const GET_ORDER_STATS_BY_DATE =
	"admin/get-order-stats-by-date-for-admin";
export const GET_ORDER_STATS_BY_TIME =
	"admin/get-order-stats-by-time-for-admin";
export const ORDERS_TO_EMAIL_URL = "admin/orders/by/meal/email";

// Users
export const GET_USERS_URL = "marketing/user";
export const GET_USER_STATS = "admin/get-user-order-stats-for-admin";
export const GET_USERS = "admin/get-user-stats-for-admin";
