import { useContext } from "react";
import moment from "moment";
import errorHandler from "../../helpers/errorHandler";
import {
	CANCEL_ORDER_URL,
	GET_BRAND_ORDERS_BY_DATE,
	GET_COKITCHENS,
	GET_COKITCHENS_URL,
	GET_LOGISTICS_URL,
	GET_LOGISTICS_URL_ONLY,
	GET_MEAL_ORDERS_BY_DATE,
	GET_ORDERS_BY_BRAND_URL,
	GET_ORDERS_URL,
	GET_ORDER_STATS_BY_DATE,
	GET_ORDER_STATS_BY_TIME,
	GET_USERS,
	GET_USER_STATS,
	ORDERS_TO_EMAIL_URL,
} from "../../helpers/urls";
import { LIVE_TRIPS } from "../../router/router";
import dataService from "../../services/data.service";

const WALLET_API_URL = process.env.REACT_APP_WALLET_API_URL;
	 
export const getOnlyLogistics = async (
	success = () => {},
	alert,
	shouldAlert,
	data,
	ordersbyBrandSuccess,
	ordersByMealSuccess,
	ordersStatsByDateSuccess,
	orderStatsByTimeSuccess,
	location
) => {

	try {
		let response = await dataService.postAuthData(GET_LOGISTICS_URL_ONLY, data);
		let res = response.data && response.data;
		success(res);
		shouldAlert &&
			alert.show("Logistics data loaded successfully!", {
				type: "success",
			});
		return res;
	} catch (error) {
		errorHandler(error, alert);
	} finally {
		await Promise.all([
			getBrandOrdersbyDate(alert, data, ordersbyBrandSuccess),
			getMealOrdersbyDate(alert, data, ordersByMealSuccess),	
			getOrderStatsByDate(alert, ordersStatsByDateSuccess, location),
			getOrderStatsByTime(alert, orderStatsByTimeSuccess, location),
		]);
	}
};
export const getActiveUsers = async (
	selectionRange,
	cokitchen_id,
	alert,
	shouldAlert,

) => {
	try {
		let response = await dataService.getAuthData("admin/active-users", {
			params: {
				cokitchen_id: cokitchen_id,
				start_date: moment(selectionRange.startDate).format("YYYY-MM-DD"),
				end_date: moment(selectionRange.endDate)
					.add(1, "days")
					.format("YYYY-MM-DD"),
			},
		});
		let res = response.data && response.data;
		return res;
	} catch (error) {
		errorHandler(error, alert);
	} finally {
		
	}
};
export const getInternalExternalRev = async (selectionRange) => {
	try {
		let response = await dataService.getAuthData(
			`external-revenue/internal/total-revenue`,
			{
				baseUrl: WALLET_API_URL,
				params: {
					start_date: moment(selectionRange.startDate).format("YYYY-MM-DD"),
					end_date: moment(selectionRange.endDate)
						.add(1, "days")
						.format("YYYY-MM-DD"),
				},
			}
		);
		let res = response.data && response.data;

		return res;
	} catch (error) {
		errorHandler(error, alert);
	} finally {
	}
};

export const getBrandOrdersbyDate = async (
	alert,
	data,
	ordersbyBrandSuccess
) => {
	try {
		let response = await dataService.postAuthData(
			GET_BRAND_ORDERS_BY_DATE,
			{...data}
		);

		let res = response?.data?.data;
		ordersbyBrandSuccess(res);
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getMealOrdersbyDate = async (alert, data, ordersByMealSuccess) => {
	try {
		let response = await dataService.postAuthData(
			GET_MEAL_ORDERS_BY_DATE,
			data
		);
		let res = response?.data?.data;
		ordersByMealSuccess(res);
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getOrderStatsByDate = async (alert, ordersStatsByDateSuccess, location) => {
	try {
		let response = await dataService.getAuthData(
			GET_ORDER_STATS_BY_DATE + `${location ? `?cokitchen_id=${location}`:''}`
		);
		let res = response?.data?.data;
		ordersStatsByDateSuccess(res);
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getOrderStatsByTime = async (alert, orderStatsByTimeSuccess, location) => {
	try {

		let response = await dataService.getAuthData(
			GET_ORDER_STATS_BY_TIME + `${location ? `?cokitchen_id=${location}`:''}`
		);
		let res = response?.data?.data;
		orderStatsByTimeSuccess(res);
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getLogistics = async (alert, shouldAlert, success) => {
	try {
		let response = await dataService.getAuthData(GET_LOGISTICS_URL);
		let res = response.data && response.data;
		let moments = res && res.trips.map((x) => moment(x.created_at));
		let maxDate = moment.max(moments).format("D MMM YY");
		let minDate = moment.min(moments).format("D MMM YY");
		res.max_date = maxDate;
		res.min_date = minDate;
		success(res);
		shouldAlert &&
			alert.show("Logistics data loaded successfully!", {
				type: "success",
			});
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getUsersStats = async (alert, show, data, success) => {
	try {
		let response = await dataService.postAuthData(GET_USER_STATS, data);
		let res = response.data && response.data;
		success(res);
		show &&
			alert.show("Users order stats loaded successfully!", {
				type: "success",
			});
	} catch (error) {
		errorHandler(error, alert);
	}
};
export const getUsersData = async (alert, show, data, success) => {
	try {
		let response = await dataService.postAuthData(GET_USERS, data);

		let res = response.data && response.data;
		success(res);
		show &&
			alert.show("Users stats loaded successfully!", {
				type: "success",
			});
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const getAdminOrders = async (
	alert,
	shouldAlert,
	setOrders,
	setOrdersDateRange,
	data,
	message
) => {
	try {
		let response;
		if (data) {
			response = await dataService.postAuthData(ORDERS_TO_EMAIL_URL, data);
		} else {
			response = await dataService.getAuthData(GET_ORDERS_URL);
		}
		let res = data
			? response?.data?.selected_date_range_orders
			: response?.data?.data;
		let resCopy = [];
		for (let index = 0; index < res.length; index++) {
			if (res[index].completed) {
				resCopy.push(res[index]);
			}
		}
		let moments = res?.sort(
			(a, b) => new Date(b.created_at) - new Date(a.created_at)
		);
		let maxDate = moment(moments[0]?.created_at).format("MM/DD/YYYY");
		let minDate = moment(moments[moments?.length - 1]?.created_at).format(
			"MM/DD/YYYY"
		);

		let diff = moment(new Date(maxDate)).diff(
			moment(new Date(minDate)),
			"days"
		);
		this.dateRange = diff;
		let dateData = {
			startDate: new Date(minDate),
			endDate: new Date(maxDate),
			key: "selection",
		};
		setOrdersDateRange(dateData);
		setOrders(resCopy);
		shouldAlert &&
			alert.show(data ? message : "Orders loaded successfully!", {
				type: "success",
			});
		return resCopy;
	} catch (error) {
		errorHandler(error, alert);
	}
};

// LOGISTICS

export const getCokitchens = async (alert, shouldAlert, success) => {
	try {
		let response = await dataService.getInternalData(GET_COKITCHENS);

		let res = response.data.data ;

		let i;
		let options = [];

		for (i = 0; i < res.length; i++) {
			options.push({ ...res[i], label: res[i].name, value: res[i].id });
		}
		const filteredKitchenOptions = options.filter(
			({value}) =>
				value === "85c7d173-56ed-44c8-8335-ef0eecabf454" || value ===
				"884fd2d2-c0b0-4006-acb7-77c6e7d4ee36"
		);
		success([
			{ label: "All", value: "all" },
			...filteredKitchenOptions,
		]);
		shouldAlert &&
			alert.show("Cokitchens loaded successfully!", {
				type: "success",
			});
	} catch (error) {
		errorHandler(error, alert);
	}
};

export const cancelOrders = async (alert, data, history) => {
	try {
		let response = await dataService.postAuthData(CANCEL_ORDER_URL, data);
		let res;
		res = response && response.data;
		if (res) {
			alert.show("Order cancelled successfully!", {
				type: "success",
			});
			history.push(LIVE_TRIPS);
		}
		return res;
	} catch (error) {
		errorHandler(error, alert);
		const errMsg =
			error && (error.response || error.message || error.toString() || error);
		let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
	}
};
