import React from "react";
import { IconButton } from "../buttons";
import "./styles.scss";

const Modal = ({
  showModal,
  closeModal,
  title,
  onSubmit,
  content,
  saveText,
  submitting,
  className
}) => {
  return (
    (showModal && (
      <div className={`flex_column_center modal p_20 `}>
        <button className="cursor_pointer close_button" onClick={closeModal}>
          x
        </button>
        <div className="back_drop" onClick={closeModal} />
        <form
          className={`flex_column_start half_width_desktop overflow-scroll bg-white ${className}`}
          onSubmit={onSubmit}
        >
          <button
            className="cursor_pointer close_button"
            onClick={closeModal}
            type="button"
          >
            x
          </button>
          <h6 className="bold_24px mb_35">{title}</h6>
          {content}

          {saveText && (
            <IconButton
              text={saveText}
              className="mt_35"
              loading={submitting}
            />
          )}
        </form>
      </div>
    )) ||
    null
  );
};
export default Modal;
