import Modal from "..";
import "./styles.scss";
import dataService from "../../../services/data.service";
import IconInput from "../../inputs/iconInput";
import { useEffect, useState } from "react";
import errorHandler from "../../../helpers/errorHandler";
import moment from "moment";
import { getCokitchens } from "../../../utils/superAdminStores/homeStore";
import DateTime from "react-datetime";

const WALLET_API_URL = process.env.REACT_APP_WALLET_API_URL;
const ExternalRevenueModal = ({
  show,
  closeModal,
  edit,
  initialFormState,
  onRefresh,
}) => {
  const [cokitchens, setCokitchens] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingCokitchen, setLoadingCokitchen] = useState(false);

  const [formState, setFormState] = useState(
    initialFormState ?? {
      amount: "",
      date: "",
      source: "",
      description: "",
      cokitchen_id: "",
    }
  );
  const [loading, setLoading] = useState(false);

  const handlePostData = async () => {
    try {
      setLoading(true);
      await dataService.postAuthData(
        "external-revenue/internal/record_revenue",
        {
          amount: formState?.amount,
          source: formState?.source,
          description: formState?.description,
          cokitchen_id: formState?.cokitchen_id,
          date: moment(formState.date).format("YYYY-MM-DD"),
        },
        { baseUrl: WALLET_API_URL }
      );
    } catch (error) {
      errorHandler(error, alert);
    } finally {
      setLoading(false);
      onRefresh();
      closeModal();
      setFormState({});
    }
  };

  const handleChange = (name, text) => {
    setFormState((prev) => ({ ...prev, [name]: text }));
  };
  const handleDateChange = (e) => {
    setFormState((prev) => ({ ...prev, date: moment(e).toISOString() }));
  };

  const enableBtn =
    !!formState?.amount && !!formState?.source && !!formState?.cokitchen_id;

  useEffect(() => {
    const getCokitchen = async () => {
      setLoadingCokitchen(true);
      await getCokitchens(false, false, setCokitchens);
      setLoadingCokitchen(false);
    };
    getCokitchen();
  }, []);

  return (
		<Modal
			className={"revenue-modal"}
			showModal={show}
			closeModal={() => closeModal()}
			title={`${edit ? "Edit" : "Create"} Revenue`}
			content={
				<div className="create-revenue-wrapper">
					<fieldset>
						<IconInput
							value={formState.amount}
							onChange={(amount) => handleChange("amount", amount)}
							placeholder="Enter amount"
							label={"Amount"}
							withLabel
						/>
					</fieldset>
					{!edit && (
						<fieldset>
							<IconInput
								value={formState.date}
								isDate
								dateFormat
								withLabel
								placeholder={"Select date"}
								label={"Date and time"}
								onDateChange={(date) => handleDateChange(date)}
								closeOnSelect
							/>
						</fieldset>
					)}

					<fieldset>
						<label className={`mb_15 normal_17px `}>Locations</label>
						<select
							value={formState?.location}
							className="external-revenue-select"
							name="location"
							id="location"
							onChange={(e) => handleChange("cokitchen_id", e.target.value)}
						>
							<option>N/A</option>
							{!loadingCokitchen
								? cokitchens
										?.filter((kitchen) => kitchen?.value !== "all")
										?.map((kitchen) => (
											<option value={kitchen?.value}>{kitchen?.label}</option>
										))
								: "Loading ..."}
						</select>
					</fieldset>
					<fieldset>
						<label className={`mb_15 normal_17px `}>Source</label>
						<select
							value={formState?.source}
							className="external-revenue-select"
							name="options"
							id="options"
							onChange={(e) => handleChange("source", e.target.value)}
						>
							<option>N/A</option>
							<option value="FOODCOURT_APP">FoodCourt App</option>
							<option value="FC_BUSINESS">FC Business</option>
							<option value="FC_EVENT_CATERING">FC Event Catering</option>
							<option value="FC_BAKERY_OVEN_FRESH">
								FC Bakery (Oven Fresh)
							</option>
							<option value="FC_JUICES_FRESH_PRESS">
								FC Juices (Fresh Press)
							</option>
							<option value="FC_JUICES_NECTAR">FC Juices (Nectar)</option>
							<option value="FC_COCKTAILS_THE_COCKTAIL_CLUB">
								FC Cocktails (The Cocktail Club)
							</option>
							<option value="FC_BUSINESS_LITE">
FC Business Lite							</option>
							<option value="CHOWDECK">Chowdeck</option>
							<option value="PARTNERSHIP_AND_OTHERS">
								Partnerships and others
							</option>
						</select>
					</fieldset>
					<fieldset>
						<label className={`mb_15 normal_17px `}>
							Description (optional)
						</label>
						<textarea
							value={formState.description}
							onChange={(e) => handleChange("description", e.target.value)}
							placeholder="Enter description"
							className="revenue-text-area"
							rows={5}
							cols={30}
						/>
					</fieldset>

					<button
						disabled={loading || !enableBtn}
						type="button"
						onClick={handlePostData}
					>
						{loading ? "Loading..." : "Submit"}
					</button>
				</div>
			}
		/>
	);
};

export default ExternalRevenueModal;
