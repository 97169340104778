import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { HomeContext } from "../../contexts/HomeContext";
import Loader from "react-loader-spinner";
import moment from "moment";
import { RiEBikeFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { FiChevronsDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import { TiGroup } from "react-icons/ti";
import { FaUserAlt } from "react-icons/fa";
import useInfiniteLoad from "../../helpers/useInfiniteLoad";
import { Link } from "react-router-dom";
import { ChevronRight, Clock, Stars } from "../../assets/icons/icons";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { numberWithCommas } from "../../helpers/commaSeparator";
import "./styles.scss";

import { withAlert } from "react-alert";

const Trips = ({ alert, selectionRange, location }) => {
	const {
		tripPage,
		setTripPage,
		allTrips,
		setAllTrips,
		allLogistics,
		setAllLogistics,
	} = useContext(HomeContext);
	const [page, setPage] = useState(tripPage);
	useEffect(() => {
		setPage(tripPage);
	}, [tripPage]);
	// Infinite scrolling
	const {
		isLoading,
		error,
		logisticsData,
		list,
		todayStart,
		yesterdayStart,
		errorEncountered,
	} = useInfiniteLoad(selectionRange, location,  page, alert);
	const loader = useRef(null);
	const handleObserver = useCallback((entries) => {
		const target = entries[0];
		if (target.isIntersecting && !errorEncountered) {
			setTripPage((prev) => prev + 1);
		}
	}, []);

	useEffect(() => {
		setAllTrips(list);
	}, [list]);

	useEffect(() => {
		setAllLogistics(logisticsData);
	}, [logisticsData]);

	useEffect(() => {
		const option = {
			root: null,
			rootMargin: "20px",
			threshold: 0,
		};
		const observer = new IntersectionObserver(handleObserver, option);
		if (loader.current) observer.observe(loader.current);
	}, [handleObserver]);

	// Scrollto top of orders
	const orderRef = useRef(null);
	const scrollToElement = () =>
		orderRef.current.scrollIntoView({ behavior: "smooth" });
	// Here we use item offsets; we could also use page offsets
	// following the API or data you're working with.
	// const [itemOffset, setItemOffset] = useState(0);

	// const itemsPerPage = 30;

	return (
		<div>
			<div className="border_bottom_thin flex_row_between py_15">
				<p className="bold_18_px">Trips shown ({list?.length || 0})</p>
			</div>
			<div
				style={{
					maxHeight: "700px",
					minHeight: "700px",
					overflow: "scroll",
					position: "relative",
				}}
				className="width_full bottom_left_section"
			>
				<table
					className="width_full scroll-container overflow-scroll "
					style={{
						maxHeight: "700px",
						minHeight: "700px",
						overflow: "scroll",
					}}
				>
					<tbody>
						<div ref={orderRef} />
						 
						{list ? list
							?.sort(
								(a, b) =>
									new Date(b.completed_time) - new Date(a.completed_time)
							)

							?.map((item, i) => {
								const isGroupOrder = item?.calculated_order?.is_group_order
									? true
									: false;
								return (
									<>
										{moment(item.completed_time).format("LLL") ===
											todayStart && (
											<div className="border_bottom_thin flex_row_between py_15">
												<p className="bold_18_px">Today</p>
											</div>
										)}
										{moment(item.completed_time).format("LLL") ===
											yesterdayStart && (
											<div className="border_bottom_thin flex_row_between py_15">
												<p className="bold_18_px">Yesterday</p>
											</div>
										)}
										<tr key={i + "item"} className="position-relative">
											<td>
												<div className="flex_column_left">
													{i + 1}
													<label className="gray_label mb_4">
														{item &&
															item.completed_time &&
															moment(item.completed_time).format("LT")}
													</label>
													<label className="gray_label mb_4">
														{item &&
															item.completed_time &&
															moment(item.completed_time).format("DD MMM YYYY")}
													</label>
													<Link
														to={``}
														className="bold_21px gray_label uppercase"
													>
														{" "}
														#{item && item.order_code}
													</Link>
												</div>
											</td>
											<td>
												<div className="flex_column_left">
													<div className="flex_row_align_center mb_10">
														<span className="normal_53px green_text mr_10">
															•
														</span>
														<p className="normal_15px">
															From: <span className="bold">CoKitchen</span>
														</p>
													</div>

													<div className="flex_row_align_center">
														<span className="normal_53px red_text mr_10">
															•
														</span>
														<p className="normal_15px">
															To:{" "}
															<span className="bold">
																{item &&
																	item.calculated_order &&
																	item.calculated_order.address_details &&
																	item.calculated_order.address_details
																		.address_line}
															</span>
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<Clock
															fill="#939393"
															width="8px"
															height="8px"
															className="mr_15"
														/>

														<p className="normal_15px bold green_text">
															Completed
														</p>
													</div>

													<div className="flex_row_align_center mb_17">
														<HiOutlineOfficeBuilding
															style={{
																stroke: "#939393",
															}}
															width="9px"
															height="9px"
															className="mr_15"
														/>

														<p className="normal_15px">
															{item?.calculatd_order?.pickup? "PICKUP":"DELIVERY"}
														</p>
													</div>

													<div className="flex_row_align_center mb_17">
														{isGroupOrder ? (
															<TiGroup
																color="#d50000"
																size="14px"
																className="mr-1"
															/>
														) : (
															<FaUserAlt
																color="#d50000"
																size="11px"
																className="mr-1"
															/>
														)}

														<p className="normal_15px">
															{isGroupOrder ? "Group" : "Single"} Order
														</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_left">
													<div className="flex_row_center mb_17">
														<RiEBikeFill
															color="#939393"
															size="14px"
															className="mr_15"
														/>

														<div className="normal_15px">
															{" "}
															{item &&
																item.rider &&
																item.calculatd_order?.rider?.first_name +
																	" " +
																	item?.calculated_order?.rider?.last_name +
																	" (Rider)"}
														</div>
													</div>

													<div className="flex_row_center mb_17">
														<FaUser
															color="#939393"
															size="12px"
															className="mr_15"
														/>

														<div className="normal_15px">
															{" "}
															{item?.calculated_order.user?.first_name +
																" " +
																item?.calculated_order?.user?.last_name}
														</div>
													</div>

													<div className="flex_row_align_center">
														<Stars fill="#939393" width="9px" />

														<p className="normal_15px">No comment</p>
													</div>
												</div>
											</td>

											<td>
												<div className="flex_column_right">
													<span className="bold_21px green_text mb_17">
														₦
														{item?.calculated_order?.total_amount &&
															numberWithCommas(
																parseInt(item.calculated_order.total_amount)
															)}
													</span>

													<div className="flex_row_right">
														<Link
															to={`full-trip-details/live/${
																item && item.order_code
															}`}
															onClick={() => {
																setAllTrips(list);
																setTripPage(tripPage + 1);
																setAllLogistics(logisticsData);
															}}
														></Link>
													
													</div>
												</div>
											</td>
										</tr>
									</>
								);
							}): <p className="flex justify-center">No trips found</p>}
						{isLoading && (
							<Loader
								type="ThreeDots"
								color="#ff000060"
								height={10}
								width={50}
								className="loader-flex"
							/>
						)}
					</tbody>
				</table>
				<button
					className="bg-light-gray rounded-circle d-justify-center d-align-center"
					style={{
						width: 50,
						height: 50,
						position: "fixed",
						right: 50,
						bottom: 50,
					}}
					onClick={() => scrollToElement()}
				>
					<FiChevronUp
						fill="#000"
						width="22px"
						height="22px"
						className=""
						size={20}
					/>
				</button>
				<div
					ref={loader}
					style={{ width: "100%", height: "50px", zIndex: 20 }}
					className="d-justify-center d-align-center"
				>
					<FiChevronsDown
						fill="#C4C4C4"
						width="12px"
						height="12px"
						className=""
						size={20}
					/>
				</div>
			</div>
			<div style={{ height: "20px" }} />
		</div>
	);
};

export default withAlert()(Trips);
