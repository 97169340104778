import React, { useEffect, useState } from "react";
import "./styles.scss";
import Loader from "react-loader-spinner";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { PieChart } from "react-minimal-pie-chart";
import Chart from "react-apexcharts";
import { numberWithCommas } from "../../helpers/commaSeparator";
import Modal from "../modals";
import { BiSearchAlt2 } from "react-icons/bi";
import IconInput from "../inputs/iconInput";
import moment from "moment";
import Select from "../select";
const RateCard = ({
	cardLabel,
	withLink,
	link,
	date,
	className,
	loading,
	rateOne,
	rateTwo,
	value = 0,
	maxValue = 1,
	type,
	align = "center",
	data = [],
	seriesName,
	pieData = [],
	footerClass = "bold text-gold",
	modalTitle,
	modalBody = [],
	modalType,
	withChartType,
	isHorizontal,
	isMeal,
}) => {
	const [show, setShow] = useState(false);

	const [chartType, setChartType] = useState("Daily");
	const [searchValue, setSearchValue] = useState("");
	const [sortVal, setSortVal] = useState({
		label: "Sort By: Default",
		value: "default",
	});
	const chartTypes = ["Daily", "Annually"];
	return (
		<div
			className={`text_card_container px-1 flex-column d-justify-between d-align-${align} bg-white ${className}`}
			style={{ width: "100%", height: type === "graph" ? 420 : "auto" }}
		>
			<div className="flex_row_between width_full mb-1 py-1">
				<label className="bold_21px">{cardLabel}</label>
				{withLink && link}
				{withChartType && (
					<div className="d-justify-end d-align-center w-50">
						{chartTypes.map((item, i) => (
							<a
								target="_blank"
								className={`cursor_pointer ${
									item === chartType
										? "date_filter_button"
										: "date_filter_button_inactive"
								} ${i === chartTypes.length - 1 ? "" : "mr-1"}`}
								onClick={() => setChartType(item)}
							>
								{item}
							</a>
						))}
					</div>
				)}
			</div>

			<div className={`w-100 ${type !== "graph" ? "d-justify-center" : ""}`}>
				{loading ? (
					<Loader
						type="ThreeDots"
						color="#1fc900 "
						height={10}
						width={50}
						className="justify-loader"
					/>
				) : rateOne?.includes("undefined") ? (
					<p className="text-center">
						Cokitchen data not found, Kindly refresh and try again
					</p>
				) : type === "progressBar" ? (
					<div style={{ width: 150, height: 150 }}>
						<CircularProgressbar
							value={value}
							maxValue={maxValue}
							strokeWidth="15"
							styles={buildStyles({
								// Rotation of path and trail, in number of turns (0-1)
								rotation: 0.25,

								// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
								strokeLinecap: "butt",

								// Text size
								textSize: "16px",

								// How long animation takes to go from one percentage to another, in seconds
								pathTransitionDuration: 0.5,

								// Can specify path transition in more detail, or remove it entirely
								// pathTransition: 'none',

								// Colors
								pathColor: `#431172`,
								textColor: "#f88",
								trailColor: "#d1831c",
								backgroundColor: "#3e98c7",
							})}
						/>{" "}
					</div>
				) : type === "pie" ? (
					pieData?.[0]?.value ? (
						<div
							className={`${!isHorizontal && ""}`}
							style={{
								display: "flex",
								flexDirection: `${isHorizontal && "column"}`,
								alignItems: "center",
								justifyContent: "center",
								gridGap: "2rem",
							}}
						>
							<div
								style={{
									width: isMeal ? "90%" : isHorizontal ? 350 : 150,
									height: isHorizontal ? 300 : 150,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
								}}
								className="mr-3"
							>
								<PieChart
									data={pieData}
									animate={true}
									paddingAngle={0}
									labelPosition={70}
									// viewBoxSize={[80, 80]}
									label={({ x, y, dx, dy, dataEntry }) => (
										<text
											x={x}
											y={y}
											dx={dx}
											dy={dy}
											dominant-baseline="central"
											text-anchor="middle"
											style={{
												fontSize: `${
													isMeal && isHorizontal
														? "1px"
														: isHorizontal
														? "2.5px"
														: "7px"
												}`,
												fontFamily: "sans-serif",
												color: "#ffffff",
												fill: "#ffffff",
												fontWeight: "bold",
												// marginInline: "auto",

												// opacity:
												// 	parseFloat(dataEntry.percentage).toFixed(1) > 0 ? 1 : 0,
											}}
										>
											{parseFloat(dataEntry.percentage).toFixed(1) + "%"}
										</text>
									)}
									labelStyle={{
										fontSize: "10px",
										fontFamily: "sans-serif",
										color: "#ffffff",
										fill: "#ffffff",
									}}
								/>
							</div>
							<div className="flex-column">
								{pieData?.map((item, i) => {
									return (
										<p
											className="d-justfy-center d-align-center text-14"
											key={item.title + i}
										>
											<span
												style={{ color: item.color }}
												className="mr-1 text-xl mb-0"
											>
												⁍
											</span>
											{item.title} (
											{(item?.value && numberWithCommas(item.value)) || 0}){" "}
											{item?.orders ? `(${item?.orders?.length})` : null}
										</p>
									);
								})}
							</div>
						</div>
					) : (
						<p>No external revenue for this date period</p>
					)
				) : type === "graph" ? (
					<Chart
						options={{
							chart: {
								id: "apexchart-example",
								type: "area",
							},
							dataLabels: {
								enabled: true,
							},
							stroke: {
								curve: "smooth",
							},
						}}
						series={[
							{
								name: seriesName,
								data: data,
							},
						]}
						type="area"
						width={"100%"}
						height={300}
					/>
				) : null}
			</div>

			<div className="d-justify-between d-align-end w-100 mt-2 ">
				{!loading && <label className="gray normal_16px mb-1">{date}</label>}
				{!loading && !rateOne?.includes("undefined") && (
					<div className="flex-column d-justify-end d-align-end">
						<p className="normal_16px bold text-purple">{rateOne}</p>
						<p
							className={`normal_16px ${footerClass}`}
							onClick={() => {
								if (modalTitle) {
									setShow(true);
								}
							}}
						>
							{rateTwo}
						</p>
					</div>
				)}
			</div>
			<Modal
				showModal={show}
				closeModal={() => setShow((prev) => !prev)}
				title={modalTitle}
				content={
					modalType === "meal" ? (
						<table className="w-100">
							<thead>
								<tr>
									<th>Meals</th> <th>Number or orders</th>
								</tr>
							</thead>
							<tbody>
								{modalBody.map((item, i) => {
									return (
										<tr key={i + "Item"}>
											<td> {item.title}</td>
											<td> {item.value}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					) : modalType === "orders" || modalType === "purchase" ? (
						<div className="w-100 ">
							<div className="border_bottom_thin flex_row_between pb-3 w-100">
								<Select
									containerClass="w-50 mr-3"
									placeholder="Locations: ALL"
									value={sortVal}
									handleChange={(e) => setSortVal(e)}
									options={[
										{
											label: "Sort By: Default",
											value: "default",
										},
										{ label: "Sort By: Ascending orders", value: "ascending" },
										{
											label: "Sort By: Descending orders",
											value: "descending",
										},
										{ label: "Sort By: Registration Date", value: "reg" },
									]}
								/>
								<IconInput
									containerClass="w-100"
									placeholder="Search by name or number of orders"
									withIcon
									icon={
										<BiSearchAlt2 width="20px" height="20px" fill="#8B8B8B" />
									}
									value={searchValue}
									onChange={(e) => setSearchValue(e)}
								/>
							</div>
							<table className="w-100">
								<thead>
									<tr>
										<th>S/N</th> <th>Users</th> <th>Number of orders</th>
										<th>Total value</th>
										<th>Registration date</th>
									</tr>
								</thead>
								<tbody>
									{modalBody
										.sort((a, b) => {
											if (
												sortVal?.value === "default" &&
												modalType === "orders"
											) {
												return b.orders - a.orders;
											} else if (
												sortVal?.value === "default" &&
												modalType === "purchase"
											) {
												return b.amount - a.amount;
											} else if (sortVal?.value === "descending") {
												return parseInt(a.orders) - parseInt(b.orders);
											} else if (sortVal?.value === "ascending") {
												return parseInt(b.orders) - parseInt(a.orders);
											} else if (sortVal?.value === "reg") {
												return (
													new Date(b?.details?.userDetail?.created_at) -
													new Date(a?.details?.userDetail?.created_at)
												);
											}
										})
										.filter(
											(item) =>
												item?.title
													.toUpperCase()
													.includes(searchValue.toUpperCase()) ||
												item?.orders.toString().includes(searchValue.toString())
										)
										.filter((item) => item?.orders)
										.map((item, i) => {
											return (
												<tr key={i + "Item"}>
													<td> {i + 1}</td>
													<td> {item.title}</td>
													<td> {item.orders}</td>
													<td> ₦{numberWithCommas(item?.amount)}</td>
													<td>
														{moment(
															item?.details?.userDetail?.created_at
														).format("LLLL")}
													</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					) : null
				}
			/>
		</div>
	);
};

export default RateCard;
