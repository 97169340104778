import React from "react";
import { HiPlus } from "react-icons/hi";
import "./styles.scss";
import { Link } from "react-router-dom";
import {
  //   Dashboard
  HOME_S_ADMIN,
  LIVE_TRIPS,
  NOTIFICATIONS_S_ADMIN,
  PARTNER_DB_S_ADMIN,
} from "../../router/router";
const Tabs = ({ home, details, notifications, location_set }) => {
  return (
    <div className="tabs_container  content_section">
      <ul className="flex_row_align_center">
        <li className={`nav_item  ${home}`}>
          <Link to={HOME_S_ADMIN}>
            <div className={`normal_18px ${!home && "bg-white"}`}>
              Dashboard & Pending Trips
            </div>
          </Link>
        </li>
        <li className={`nav_item ${details}`}>
          <Link to={LIVE_TRIPS}>
            <div className={`normal_18px ${!details && "bg-white"}`}>
              Live on-going trips, history & feedback
            </div>
          </Link>
        </li>
        <li className={`nav_item ${notifications}`}>
          <Link to={NOTIFICATIONS_S_ADMIN}>
            <div className={`normal_18px ${!notifications && "bg-white"}`}>
              Notifications & alerts!
            </div>
          </Link>
        </li>

        <li className={`nav_add ${location_set}`}>
          <Link to={PARTNER_DB_S_ADMIN}>
            <div className="flex_row_right cursor_pointer">
              <label className="flex_row_center btn_span">
                <HiPlus fill="#B9B9B9" width="14px" height="14px" />
              </label>
              <label className="normal_18px ">Partner database</label>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
