import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import debounce from "lodash.debounce";
import "./styles.scss";
import axios from "axios";
import dataService from "../../services/data.service";
import { SEARCH_ORDER_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import moment from "moment";
import { ChevronRight, Clock, Stars } from "../../assets/icons/icons";
import { RiEBikeFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { numberWithCommas } from "../../helpers/commaSeparator";
import { Link } from "react-router-dom";
import { TiGroup } from "react-icons/ti";
import { FaUserAlt } from "react-icons/fa";
import useInfiniteLoad from "../../helpers/useInfiniteLoad";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import homeStore from "../../stores/superAdminStores/homeStore";

const Search = ({ searchValue, alert }) => {
	const [searchLoading, setSearchLoading] = useState(false);
	const [cancelTok, setCancelTok] = useState(null);
	const [searchResult, setSearchResult] = useState(null);

	const { todayStart, yesterdayStart } = useInfiniteLoad();
	useEffect(() => {
		if (searchValue.length > 4) {
			loadSearchResult();
		} else {
			setSearchLoading(false);
		}

		if (!searchValue) {
			setSearchResult(null);
		}
		return () => loadSearchResult.cancel;
	}, [searchValue]);

	const getSearchResult = async () => {
		let cancelToken;
		//Check if there are any previous pending requests
		if (cancelTok) {
			cancelTok.cancel("Cancelling in previous search");
		}
		//Save the cancel token for the current request
		cancelToken = axios.CancelToken.source();
		setCancelTok(axios.CancelToken.source());
		try {
			setSearchLoading(true);
			setSearchResult(null);
			let payload = { search_query: searchValue };
			const response = await dataService.postAuthData(
				SEARCH_ORDER_URL + searchValue,
				payload,
				cancelToken.token
			);
			let res = response?.data?.data;
			setSearchResult(res);
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			setSearchLoading(false);
		}
	};

	const loadSearchResult = useCallback(debounce(getSearchResult, 1000), [
		searchValue,
	]);
	return (
		<div
			className="width_full bottom_left_section"
			style={{
				maxHeight: "300px",
				overflow: "scroll",
				position: "relative",
			}}
		>
			{searchLoading && (
				<Loader
					type="ThreeDots"
					color="#ff000060"
					height={10}
					width={50}
					className="loader-flex"
				/>
			)}
			{!searchValue ? null : searchResult && !searchLoading ? (
				<div className="border_bottom_thin flex_row_between py_15">
					<p className="bold_18_px">
						Search result for "{searchValue}" ({searchResult?.length})
					</p>
				</div>
			) : null}
			{!searchValue
				? null
				: !searchLoading &&
				  searchResult?.length !== 0 &&
				  searchResult
						?.sort(
							(a, b) => new Date(b.completed_time) - new Date(a.completed_time)
						)

						.map((item, i) => {
							const isGroupOrder = item?.calculated_order?.is_group_order
								? true
								: false;

							return (
								<div
									style={{
										maxHeight: "300px",
										overflow: "scroll",
										position: "relative",
									}}
								>
									{moment(item.completed_time).format("LLL") === todayStart && (
										<div className="border_bottom_thin flex_row_between py_15">
											<p className="bold_18_px">Today</p>
										</div>
									)}
									{moment(item.completed_time).format("LLL") ===
										yesterdayStart && (
										<div className="border_bottom_thin flex_row_between py_15">
											<p className="bold_18_px">Yesterday</p>
										</div>
									)}
									<tr key={i + "item"} className="position-relative">
										<td>
											<div className="flex_column_left">
												{i + 1}
												<label className="gray_label mb_4">
													{item &&
														item.completed_time &&
														moment(item.completed_time).format("LT")}
												</label>
												<label className="gray_label mb_4">
													{item &&
														item.completed_time &&
														moment(item.completed_time).format("DD MMM YYYY")}
												</label>
												<Link
													to={``}
													className="bold_21px gray_label uppercase"
												>
													{" "}
													#{item && item.order_code}
												</Link>
											</div>
										</td>
										<td>
											<div className="flex_column_left">
												<div className="flex_row_align_center mb_10">
													<span className="normal_53px green_text mr_10">
														•
													</span>
													<p className="normal_15px">
														From: <span className="bold">CoKitchen</span>
													</p>
												</div>

												<div className="flex_row_align_center">
													<span className="normal_53px red_text mr_10">•</span>
													<p className="normal_15px">
														To:{" "}
														<span className="bold">
															{item &&
																item.calculated_order &&
																item.calculated_order.address_details &&
																item.calculated_order.address_details
																	.address_line}
														</span>
													</p>
												</div>
											</div>
										</td>

										<td>
											<div className="flex_column_left">
												<div className="flex_row_center mb_17">
													<Clock
														fill="#939393"
														width="8px"
														height="8px"
														className="mr_15"
													/>

													<p className="normal_15px bold green_text">
														Completed
													</p>
												</div>

												<div className="flex_row_align_center mb_17">
													<HiOutlineOfficeBuilding
														style={{
															stroke: "#939393",
														}}
														width="9px"
														height="9px"
														className="mr_15"
													/>

													<p className="normal_15px">
														{homeStore?.logistics?.logistics_companies?.find(
															(itm) =>
																itm?.id === item?.rider?.logistics_company_id
														)?.name || "PICKUP"}
													</p>
												</div>

												<div className="flex_row_align_center mb_17">
													{isGroupOrder ? (
														<TiGroup
															color="#d50000"
															size="14px"
															className="mr-1"
														/>
													) : (
														<FaUserAlt
															color="#d50000"
															size="11px"
															className="mr-1"
														/>
													)}

													<p className="normal_15px">
														{isGroupOrder ? "Group" : "Single"} Order
													</p>
												</div>
											</div>
										</td>

										<td>
											<div className="flex_column_left">
												<div className="flex_row_center mb_17">
													<RiEBikeFill
														color="#939393"
														size="14px"
														className="mr_15"
													/>

													<Link to={``} className="normal_15px">
														{" "}
														{item &&
															item.rider &&
															item.rider.first_name +
																" " +
																item.rider.last_name +
																" (Rider)"}
													</Link>
												</div>

												<div className="flex_row_center mb_17">
													<FaUser
														color="#939393"
														size="12px"
														className="mr_15"
													/>

													<Link to={``} className="normal_15px">
														{" "}
														{item?.user?.first_name +
															" " +
															item?.user?.last_name}
													</Link>
												</div>

												<div className="flex_row_align_center">
													<Stars fill="#939393" width="9px" />

													<p className="normal_15px">No comment</p>
												</div>
											</div>
										</td>

										<td>
											<div className="flex_column_right">
												<span className="bold_21px green_text mb_17">
													₦
													{item?.calculated_order?.delivery_fee &&
														numberWithCommas(
															parseInt(item?.calculated_order?.delivery_fee)
														)}
												</span>

												<div className="flex_row_right">
													<Link
														to={`full-trip-details/live/${
															item && item?.order_code
														}`}
													>
														<p className="mr_10 normal_15px black">
															See full trip details
														</p>
													</Link>
													<Link
														to={`full-trip-details/live/${
															item && item?.order_code
														}`}
													>
														<ChevronRight
															fill="#000000"
															width="11px"
															height="11px"
														/>
													</Link>
												</div>
											</div>
										</td>
									</tr>
								</div>
							);
						})}
		</div>
	);
};

export default Search;
