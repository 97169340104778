import React, { useState } from "react";
import Loader from "react-loader-spinner";
import "./styles.scss";
import { numberWithCommas } from "../../helpers/commaSeparator";
import moment from "moment";
import { externalRevenueSource } from "../../constants/constant";
import dataService from "../../services/data.service";
import errorHandler from "../../helpers/errorHandler";
const ExternalRevenueCard = ({
	loading,
	revenue,
	className,
	onClick,
	onDelete,
}) => {
		 const WALLET_API_URL =
            process.env.REACT_APP_WALLET_API_URL;
	const [hovered, setHovered] = useState(false);
	const deleteRevenue = async () => {
		try {
			let response = await dataService.postAuthData(
				`external-revenue/internal/reverse_revenue/${revenue.id}`,
				{},
				{ baseUrl: WALLET_API_URL }
			);
			if (response.data) {
				onDelete();
			}
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	return (
		<div className="card">
			{loading ? (
				<div>
					<Loader type="ThreeDots" color="#ff000060" height={10} width={50} />
				</div>
			) : (
				<div
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}
					className={`text_card_container px-1 flex-column d-justify-between d-align-center bg-white  ${className}`}
					style={{
						alignItems: "center",
					}}
				>
					<div className="flex_row_between width_full  py-1">
						<h6 className="bold_21px h5 ">
							{externalRevenueSource[revenue.source] ?? "N/A"}
						</h6>
						{hovered && (
							<div>
								<button
									className="cursor_pointer "
									type="button"
									onClick={onClick}
								>
									Edit
								</button>
								<button
									className="cursor_pointer red_text"
									type="button"
									onClick={deleteRevenue}
								>
									Delete
								</button>
							</div>
						)}
					</div>
				
						<h6 className="h5 bold green_text mb-0s ease">
							{" "}
							₦{numberWithCommas(revenue.amount)}
						</h6>

						<div>
							<p>{moment(revenue.created_at).format("DD MMM YYYY")}</p>
						</div>
				</div>
			)}
		</div>
	);
};

export default ExternalRevenueCard;
