import { observable, runInAction, action, makeAutoObservable } from "mobx";
import dataService from "../../services/data.service";
import {
	GET_ORDERS_URL,
	CANCEL_ORDER_URL,
	PAYMENT_HISTORY_URL,
	GET_LOGISTICS_URL,
	GET_USERS_URL,
	GET_ORDERS_BY_BRAND_URL,
	GET_ORDERS_BY_MEAL_URL,
	ORDERS_TO_EMAIL_URL,
	GET_LOGISTICS_URL_ONLY,
	GET_BRAND_ORDERS_BY_DATE,
	GET_MEAL_ORDERS_BY_DATE,
	GET_USER_STATS,
	GET_USERS,
	GET_ORDER_STATS_BY_DATE,
	GET_ORDER_STATS_BY_TIME,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import { LIVE_TRIPS } from "../../router/router";
import moment from "moment";

export class HomeStore {
	@observable orders = {};
	@observable logistics = {};
	@observable ongoingTrips = [];
	@observable users = [];
	@observable onlyLogistics = {};
	@observable dataCached = false;
	@observable dataUsersCached = false;
	@observable dataFBCached = false;
	@observable dataBrandsCached = false;
	@observable dataMealsCached = false;
	@observable ordersDateRange = "";
	@observable usersDateRange = "";
	@observable ordersByBrand = [];
	@observable ordersByMeal = [];
	@observable allTrips = [];
	@observable userStatistics = [];
	@observable userData = [];
	@observable ordersByDate = [];
	@observable ordersByTime = [];
    @observable dataLoading = false
	@observable tripPage = 1;
	@observable dateRange = 0;
	@observable dateRangeReg = 0;

	constructor() {
		makeAutoObservable(this);
	}

	@action updateOngoingTrips = (item) => {
		this.ongoingTrips = item;

		this.dataFBCached = true;
	};

	@action updateAllTrips = (item) => {
		this.allTrips = item;
	};

	@action updateTripPage = (item) => {
		this.tripPage = item;
	};

	sendToEmail = async (alert, data, message) => {
		try {
			let response = await dataService.postAuthData(ORDERS_TO_EMAIL_URL, data);

			runInAction(() => {
				let res = response?.data?.selected_date_range_orders;
				if (res) {

					alert.show(message, {
						type: "success",
					});
				}
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	getAdminOrders = async (alert, shouldAlert, data, message) => {
		try {
			let response;
			if (data) {
				response = await dataService.postAuthData(ORDERS_TO_EMAIL_URL, data);
			} else {
				// response = await dataService.getAuthData(GET_LOGISTICS_URL+"/5");
				response = await dataService.getAuthData(GET_ORDERS_URL);
			}
			runInAction(() => {
				let res = data
					? response?.data?.selected_date_range_orders
					: response?.data?.data;
				// let res =data? response?.data?.selected_date_range_orders: response?.data?.trips;
				let resCopy = [];
				for (let index = 0; index < res.length; index++) {
					if (res[index].completed) {
						resCopy.push(res[index]);
					}
				}
				let moments = res?.sort(
					(a, b) => new Date(b.created_at) - new Date(a.created_at)
				);
				let maxDate = moment(moments[0]?.created_at).format("MM/DD/YYYY");
				let minDate = moment(moments[moments?.length - 1]?.created_at).format(
					"MM/DD/YYYY"
				);

				let diff = moment(new Date(maxDate)).diff(
					moment(new Date(minDate)),
					"days"
				);
				this.dateRange = diff;
				this.ordersDateRange = {
					startDate: new Date(minDate),
					endDate: new Date(maxDate),
					key: "selection",
				};
				this.dataCached = true;
				this.orders = resCopy;
				shouldAlert &&
					alert.show(data ? message : "Orders loaded successfully!", {
						type: "success",
					});
				return resCopy;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	getLogistics = async (alert, shouldAlert) => {
		try {
			let response = await dataService.getAuthData(GET_LOGISTICS_URL);

			runInAction(() => {
				let res = response.data && response.data;
				let moments = res && res.trips.map((x) => moment(x.created_at));
				let maxDate = moment.max(moments).format("D MMM YY");
				let minDate = moment.min(moments).format("D MMM YY");
				res.max_date = maxDate;
				res.min_date = minDate;
				this.dataCached = true;
				this.logistics = res;
				shouldAlert &&
					alert.show("Logistics data loaded successfully!", {
						type: "success",
					});
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	cancelOrders = async (alert, data, history) => {
		try {
			let response = await dataService.postAuthData(CANCEL_ORDER_URL, data);
			let res;
			runInAction(() => {
				res = response && response.data;
				if (res) {
					alert.show("Order cancelled successfully!", {
						type: "success",
					});

					this.dataCached = true;

					history.push(LIVE_TRIPS);
				}
			});
			return res;
		} catch (error) {
			errorHandler(error, alert);
			const errMsg =
				error && (error.response || error.message || error.toString() || error);
			let errorMsg = (errMsg && errMsg.data && errMsg.data.message) || errMsg;
		}
	};

	getOnlyLogistics = async (alert, shouldAlert, data, loading) => {
		
		try {
			
			
			let response = await dataService.postAuthData(
				GET_LOGISTICS_URL_ONLY,
				data
			);

			runInAction(() => {
				this.dataLoading=true
				let res = response.data && response.data;

				this.onlyLogistics = res;
				shouldAlert &&
					alert.show("Logistics data loaded successfully!", {
						type: "success",
					});
					this.dataLoading=false
			});
		} catch (error) {
			errorHandler(error, alert);
		} finally {
			await Promise.all([
				this.getBrandOrdersbyDate(alert, data),
				this.getMealOrdersbyDate(alert, data),
				this.getOrderStatsByDate(),
				this.getOrderStatsByTime(),
			]);
		}
	};

	getUsers = async (alert, show) => {
		try {
			let response = await dataService.getAuthData(GET_USERS_URL);

			runInAction(() => {
				let res = response.data && response.data.data;
				let moments = res?.map((x) => moment(x.created_at));
				let maxDate = moment.max(moments).format("MM/DD/YYYY");
				let minDate = moment.min(moments).format("MM/DD/YYYY");

				let diff = moment(new Date(maxDate)).diff(
					moment(new Date(minDate)),
					"days"
				);
				this.dateRangeReg = diff;
				this.usersDateRange = {
					startDate: new Date(minDate),
					endDate: new Date(maxDate),
					key: "selection",
				};
				this.users = res;
				show &&
					alert.show("users loaded successfully!", {
						type: "success",
					});

				this.dataUsersCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	getUsersStats = async (alert, show, data) => {
		try {
			let response = await dataService.postAuthData(GET_USER_STATS, data);

			runInAction(() => {
				let res = response.data && response.data;
				this.userStatistics = res;
				show &&
					alert.show("users loaded successfully!", {
						type: "success",
					});

				this.dataUsersCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};
	getUsersData = async (alert, show, data) => {
		try {
			let response = await dataService.postAuthData(GET_USERS, data);

			runInAction(() => {
				let res = response.data && response.data;
				this.userData = res;
				show &&
					alert.show("users loaded successfully!", {
						type: "success",
					});

				this.dataUsersCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getOrdersByBrand = async (alert) => {
		try {
			let response = await dataService.getAuthData(GET_ORDERS_BY_BRAND_URL);

			runInAction(() => {
				let res = response?.data?.data;
				this.ordersByBrand = res;
				this.dataBrandsCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getBrandOrdersbyDate = async (alert, data) => {
		try {
			let response = await dataService.postAuthData(
				GET_BRAND_ORDERS_BY_DATE,
				data
			);

			runInAction(() => {
				let res = response?.data?.data;
				this.ordersByBrand = res;
				this.dataBrandsCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getMealOrdersbyDate = async (alert, data) => {
		try {
			let response = await dataService.postAuthData(
				GET_MEAL_ORDERS_BY_DATE,
				data
			);

			runInAction(() => {
				let res = response?.data?.data;
				this.ordersByMeal = res;
				this.dataMealsCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getOrderStatsByDate = async (alert, data) => {
		try {
			let response = await dataService.getAuthData(GET_ORDER_STATS_BY_DATE);
			runInAction(() => {
				let res = response?.data?.data;
				this.ordersByDate = res;
				this.dataBrandsCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getOrderStatsByTime = async (alert, cokitchen_id) => {
		try {
			let response = await dataService.getAuthData(GET_ORDER_STATS_BY_TIME + `/${cokitchen_id}`);
			runInAction(() => {
				let res = response?.data?.data;
				this.ordersByTime = res;
				this.dataBrandsCached = true;
			});
		} catch (error) {
			errorHandler(error, alert);
		}
	};

	getOrdersByBrandAndMeals = async (alert) => {
		try {
			await Promise.all([this.getOrdersByBrand(), this.getOrdersByMeals()]);
		} catch (error) {
			errorHandler(error, alert);
		}
	};
}
export default new HomeStore();
