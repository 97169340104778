import React from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";

import "./styles.scss";

// export type PaginateProps = ReactPaginateProps & {
// 	containerClass?: string,
// };

export default function Paginate({
	containerClass = "",
	...paginateProps
}) {
	return (
        <div className={containerClass}>
			{paginateProps.pageCount <= 1 ? null : (
				<div className="pagination_container">
					<ReactPaginate
						previousLabel={'Prev'}
						nextLabel={'Next'}
						breakLabel={<span>..</span>}
						marginPagesDisplayed={2}
						containerClassName="pagination"
						previousLinkClassName="prev_link"
						nextLinkClassName="next_link"
						disabledClassName="pagination__link--disabled"
						activeClassName="pagination__link--active"
						{...paginateProps}
					/>
				</div>
			)}
		</div>
	);
}
